import { Modal } from "antd";




const RepeatCourse = ({repeatcoursemodal,set_repeatcoursemodal}) => {

    return(
        <>
<Modal>

</Modal>
        </>
    )
}

export default RepeatCourse;