/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CLASSROOMS_LIST,
  DELETE_SEMESTER,
  FACULTIES_LIST,
  SEMESTERS_COURSES_LIST,
  SEMESTERS_LIST,
  STATUS_SEMESTER,
  EDIT_CALENDAR,
  ADD_CLASS_SECTION,
  REMOVE_CLASS_SECTION
} from "../../../apis/apis";
import {
  Button,
  Checkbox,
  Popconfirm,
  Table,
  notification,
  MenuProps,
  Tag,
  Space,
  Dropdown,
  Popover,
  Select,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  SmileOutlined,
  DownOutlined,
  EllipsisOutlined,
  MoreOutlined,
  StopOutlined,
} from "@ant-design/icons";
import SisLoader from "../../../widgets/loader";
import Search from "antd/es/transfer/search";
import SearchCourse from "./SearchCourse";
import RoomColumn from "./RoomColumn";
import FacultyColumn from "./FacultyColumn";
import TimingColumn from "./TimingColumn";
import SectionSchedule from "./SectionSchedule";
import { render } from "@testing-library/react";
const SemesterCourses = (props) => {
  const { id, sem_id } = useParams();
  const history = useLocation();
  const navigate = useNavigate();
  const [loader, set_loader] = useState(true);
  const [r_loader, set_r_loader] = useState(false);
  const [errors, set_errors] = useState([]);
  const [table_list, set_table_list] = useState([]);
  const [sem_courses_list, set_sem_courses_list] = useState([]);
  const [faculty_list, set_faculty_list] = useState([]);
  const [room_list, set_room_list] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  // Form State
  const [title, set_title] = useState("");
  const [description, set_description] = useState("");
  const [calendar_year, set_calendar_year] = useState("");
  const [calendar_start, set_calendar_start] = useState("");
  const [calendar_end, set_calendar_end] = useState("");

  const ref1 = useRef(null);

  const VIEW_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("id", id);
    FORM_DATA.append("token", USER_DATA.token);
    const EDIT_ROLE_API_RESPONSE = await EDIT_CALENDAR(FORM_DATA);
    if (EDIT_ROLE_API_RESPONSE?.data?.status) {
      set_title(EDIT_ROLE_API_RESPONSE?.data?.calendar?.title);
      set_calendar_start(
        EDIT_ROLE_API_RESPONSE?.data?.calendar?.year_start_date
      );
      set_calendar_end(EDIT_ROLE_API_RESPONSE?.data?.calendar?.year_end_date);
      set_description(EDIT_ROLE_API_RESPONSE?.data?.calendar?.description);
    } else {
      // set_errors(EDIT_ROLE_API_RESPONSE.data.errors);
    }
  };
  const steps = [
    {
      title: "Other Actions",
      description: "Click to see other actions.",
      target: () => ref1.current,
    },
  ];

  // const items: MenuProps['items'] = [
  //     {
  //         label: <a>1st menu item</a>,
  //         key: '0',
  //     },
  // ]

  // Table data
  const room_print = (data) => {
    return (
      <>
        <div>
          <p>Content</p>
          <p>Content</p>
        </div>
      </>
    );
  };
  const columns = [
    {
      title: "Course",
      render: (text, record) => {
        return (
          <>
           <span style={{fontSize:"12px"}}> {record.title} ({record.code}) <span style={{fontSize:"10px", backgroundColor:"red", color:"#fff", padding:"1px 7px", borderRadius:"5px"}}>SEC-{record.section_name}</span></span>
          </>
        );
      },
      // children: [
      //     {

      //       name: 'John Brown',
      //       age: 42,
      //       address: 'New York No. 2 Lake Park',
      // }]
    },
    {
      title: "Room",
      render: (text, record) => {
        return (
          <>
            <RoomColumn
              calendar_id={id}
              item={record}
              sem_id={sem_id}
              room_list={room_list}
            />
          </>
        );
      },
    },
    {
      title: "Timing",
      render: (text, record) => {
        return (
          <>
            <TimingColumn
              set_r_loader={set_r_loader}
              calendar_id={id}
              item={record}
              sem_id={sem_id}
            />
          </>
        );
      },
    },
    {
      title: "Faculty",
      render: (text, record) => {
        return (
          <>
            <FacultyColumn
              calendar_id={id}
              item={record}
              sem_id={sem_id}
              faculty_list={faculty_list}
            />
          </>
        );
      },
    },

    {
      title: "Enrl. students",
      render: (text, record) => {
        return (
          <>
            <span content={room_print} title="Title">
              <p>{record.enrollment_students}</p>
            </span>
          </>
        );
      },
    },
    {
      title: "Scheduled",
      render: (text, record) => {
        return (
          <>
            <span content={room_print} title="Title">
              <p>{record.scheduled}</p>
            </span>
          </>
        );
      },
    },
    {
      title: "Action",
      key: "operation",
      render: (text, record) => (
        <>
          <Popover title="Action" content={<>
              <Button type="primary" onClick={() => ADD_API(record)} size="small" style={{width:"100%", marginBottom:"8px"}}>Add Section</Button><br/>
              <Popconfirm
    title="Delete the Section"
    description="Are you sure to delete this section?"
    onConfirm={() => REMOVE_API(record)}
    okText="Yes"
    cancelText="No"
  >
<Button type="primary" size="small" style={{width:"100%"}} danger>Remove Row</Button>
  </Popconfirm>
              
          </>} trigger="click">
          <MoreOutlined style={{cursor:"pointer"}} />
          </Popover>
        </>
      ),
    },
  ];

  const data = [];
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = { selectedRowKeys, onChange: onSelectChange };
  const SEMESTERS_COURSES_LIST_API = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("calendar_id", atob(id));
    FORM_DATA.append("semester_id", atob(sem_id));
    const API_RESPONSE = await SEMESTERS_COURSES_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      if (API_RESPONSE?.data?.courses) {
        set_sem_courses_list(API_RESPONSE?.data?.courses);
        set_loader(false);
      }
    } else {
    }
  };
  const LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("calendar_id", id);
    const API_RESPONSE = await SEMESTERS_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_table_list(API_RESPONSE.data.semesters);
      var first_semester = btoa(API_RESPONSE.data.semesters[0].id);
      if (sem_id) {
        first_semester = sem_id;
      }
    } else {
      set_loader(false);
    }
  };
  const FACULTY_LIST_API = async () => {
    const FACULTIES_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", FACULTIES_DATA.token);
    const API_RESPONSE = await FACULTIES_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_faculty_list(API_RESPONSE.data.faculty);
    }
  };
  const ROOM_LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    const API_RESPONSE = await CLASSROOMS_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_room_list(API_RESPONSE.data.classrooms);
    }
  };
  useEffect(() => {
    VIEW_API();
    FACULTY_LIST_API();
    ROOM_LIST_API();
    LIST_API();
    SEMESTERS_COURSES_LIST_API();
  }, [history]);

  const ADD_API = async (data) => {
    set_loader(true)
    const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
    const FORM_DATA = new FormData();
    FORM_DATA.append('token', USER_DATA.token);
    FORM_DATA.append('data', JSON.stringify(data));
    const API_RESPONSE = await ADD_CLASS_SECTION(FORM_DATA);
    if (API_RESPONSE.data.status) {
        notification.open({
            message: 'Success!!',
            description: 'Calendar Successfully added. Now Add semester in Academic Calendar',
            icon: <SmileOutlined style={{ color: 'green' }} />,
        });
        navigate("/semester-courses/" + id + "/" + sem_id);
    } else {
        set_errors(API_RESPONSE.data.errors);
        set_loader(false)
    }
}
const REMOVE_API = async (data) => {
    set_loader(true)
    const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
    const FORM_DATA = new FormData();
    FORM_DATA.append('token', USER_DATA.token);
    FORM_DATA.append('data', JSON.stringify(data));
    const API_RESPONSE = await REMOVE_CLASS_SECTION(FORM_DATA);
    if (API_RESPONSE.data.status) {
        notification.open({
            message: 'Success!!',
            description: 'Calendar Successfully added. Now Add semester in Academic Calendar',
            icon: <SmileOutlined style={{ color: 'green' }} />,
        });
        navigate("/semester-courses/" + id + "/" + sem_id);
    } else {
        set_errors(API_RESPONSE.data.errors);
        set_loader(false)
    }
}


  return (
    <div>
      <div className="theme-content-head">
        <div className="theme-content-left-head">
          <h3>Academic Calendar Schedule({title})</h3>
        </div>
        <div className="theme-content-right-head"></div>
      </div>
      <div className="sis-tab-menu">
        <span onClick={() => navigate("/edit-Calendar/" + id)}>
          Academic Calendar
        </span>
        <span  onClick={() =>  navigate('/calendar-semester/'+id)}>Semesters</span>
        <span
          className="active"
          onClick={() => navigate("/calendar-schedule/" + id)}
        >
          Schedules
        </span>
      </div>
      <div className="sis-tab-content">
        <div className="row">
          <div className="col-9">
            <ul className="semester-tabs">
              {table_list?.length > 0 && (
                <>
                  {table_list.map((item, index) => (
                    <>
                      {sem_id ? (
                        <>
                          {/* {atob(sem_id)}-{item.id} */}
                          {item.id === parseInt(atob(sem_id)) ? (
                            <>
                              <li
                                className="active"
                                onClick={() =>
                                  navigate(
                                    "/semester-courses/" +
                                      id +
                                      "/" +
                                      btoa(item.id)
                                  )
                                }
                              >
                                {item.title}
                              </li>
                            </>
                          ) : (
                            <>
                              <li
                                onClick={() =>
                                  navigate(
                                    "/semester-courses/" +
                                      id +
                                      "/" +
                                      btoa(item.id)
                                  )
                                }
                              >
                                {item.title}
                              </li>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <li
                            className={index === 0 ? "active" : ""}
                            onClick={() =>
                              navigate(
                                "/semester-courses/" + id + "/" + btoa(item.id)
                              )
                            }
                          >
                            {item.title}
                          </li>
                        </>
                      )}
                    </>
                  ))}
                </>
              )}
            </ul>
            {loader ? (
              <>
                {" "}
                <SisLoader />{" "}
              </>
            ) : (
              <>
                <Table
                  //  expandable={{
                  //     expandedRowRender: (record) => <>
                  //      <SectionSchedule />
                  //     </>,

                  // }}
                  size="small"
                  columns={columns}
                  dataSource={sem_courses_list}
                  defaultExpandAllRows={true}
                />
              </>
            )}
          </div>
          <div className="col-3">
            <SearchCourse
              calendar_id={id}
              select_courses={sem_courses_list}
              sem_id={sem_id}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SemesterCourses;
