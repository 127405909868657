import { LoadingOutlined, SmileOutlined } from "@ant-design/icons";
import { Button, Checkbox, Input, Spin, notification } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SignatureCanvas from 'react-signature-canvas'
import { SIS_STORAGE, AGREEMENT_SAVE, GET_AGREEMENT } from "../../../apis/apis";
import SisLoader from "../../../widgets/loader";


const Agreement = () => {

    const [agreement_check, set_agreement_check] = useState("");
    const [student_signature_ref, set_student_signature_ref] = useState("");
    const [student_signature, set_student_signature] = useState("");
    const [api_student_signature, set_api_student_signature] = useState("");
    const [student_signature_empty_error, set_student_signature_empty_error] = useState("");
    const [agreement_save_status, set_agreement_save_status] = useState("");
    const [loader, set_loader] = useState(true);
    const [errors, set_errors] = useState([]);

    const navigate = useNavigate();


    useEffect(() => {
        VIEW_AGREEMENT();
    }, []);


    const VIEW_AGREEMENT = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('student_user_id', USER_DATA.id);
        const VIEW_AGREEMENT_API_RESPONSE = await GET_AGREEMENT(FORM_DATA);
        if (VIEW_AGREEMENT_API_RESPONSE?.data?.status) {
            set_loader(false);
            localStorage.setItem('agreement_information_status',VIEW_AGREEMENT_API_RESPONSE?.data?.student_signature?.agreement_save_status);
            set_agreement_save_status(VIEW_AGREEMENT_API_RESPONSE?.data?.student_signature?.agreement_save_status);
            set_agreement_check(VIEW_AGREEMENT_API_RESPONSE?.data?.student_signature?.agreement_check);
            set_api_student_signature(VIEW_AGREEMENT_API_RESPONSE?.data?.student_signature?.student_signature);
        }
        else {
            set_loader(false);
        }
    }

    const AGREEMENT_DRAFT_NEXT = async (agreement_save_status) => {
        set_loader(true);
        set_errors("");
        set_student_signature_empty_error("")
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('student_user_id', USER_DATA.id);
        FORM_DATA.append('agreement_save_status', agreement_save_status);
        FORM_DATA.append('agreement_check', (agreement_check === undefined) || (agreement_check === null) || (agreement_check === 0) ? "" : agreement_check);
        FORM_DATA.append('student_signature', (student_signature === null) || (student_signature === "") || (student_signature === undefined) ? (api_student_signature === undefined) ? "" : api_student_signature : student_signature);
        const API_RESPONSE = await AGREEMENT_SAVE(FORM_DATA);
        if (API_RESPONSE?.data?.status) {

            if (API_RESPONSE?.data?.agreement_save_status == "1") {
                notification.open({
                    message: "Success!!",
                    description: "Agreement Save as Draft.",
                    icon: <SmileOutlined style={{ color: "green" }} />,
                });
                set_loader(false);
                VIEW_AGREEMENT();
            }
            if (API_RESPONSE?.data?.agreement_save_status == "2") {
                notification.open({
                    message: "Success!!",
                    description: "Agreement Saved Sucessfully",
                    icon: <SmileOutlined style={{ color: "green" }} />,
                });
                set_loader(false);
                navigate('/formpreview');
            }
        } else {
            set_errors(API_RESPONSE?.data?.errors);
            set_loader(false);
        }
    }

    return (
        <>
            {loader ? <SisLoader />
                : <>
                    <div className='row'>
                        <div className='online-form-step-box'>
                            <div className={'online-form-step'} onClick={() => navigate('/')}>
                                <div className='online-form-step-line'></div>
                                <span>1</span>
                                <h3>Basic Information </h3>
                            </div>
                            <div className={'online-form-step'} onClick={() => navigate('/demographic-information')}>
                                <div className='online-form-step-line'></div>
                                <span>2</span>
                                <h3>Demographic Information</h3>
                            </div>
                            <div className={'online-form-step'} onClick={() => navigate('/finance-declaration')}>
                                <div className='online-form-step-line'></div>
                                <span>3</span>
                                <h3>Finance Declaration</h3>
                            </div>
                            <div className={'online-form-step running'}>
                                <div className='online-form-step-line'></div>
                                <span>4</span>
                                <h3>Agreement</h3>
                            </div>
                            <div className={'online-form-step'} onClick={() => {
                                if (agreement_save_status == "2") navigate('/formpreview')
                            }}>
                                <div className='online-form-step-line'></div>
                                <span>5</span>
                                <h3>Preview</h3>
                            </div>
                            <div className={'online-form-step'} onClick={() => {
                                if (agreement_save_status == "2") navigate('/payment')
                            }}>
                                <span>6</span>
                                <h3>Payment</h3>
                            </div>
                        </div>
                    </div>
                    <div>
                        <br />
                        <hr />
                        <br />
                        <div className="theme-content-left-head">
                            <h3>Agreement</h3>
                        </div>
                        <br />
                    </div>
                    <p>I agree with Lincoln University’s application deadlines and admission requirements. I agree to the release of all transcripts and test scores from my previous education.</p><br />
                    <p>LI certify that the information is complete and accurate. I understand that making false statements will result in disciplinary actions, denial of admission and/or invalidation of credits or degrees earned. If admitted, I agree to abide by the policies, rules, and regulations of Lincoln University. If any information changes prior to my entry into the university, I will notify the Office of Admissions.</p><br />
                    <p>I understand that my application will not be processed without submitting of a non-refundable application fee.</p><br />

                    <div className="row" style={{ marginBottom: "0" }}>
                        <div className="col-6">
                            <div className="input-box" style={{ position: "relative" }}>
                                <label style={{ textTransform: "none" }}>
                                    Do you understand and agree to the terms listed above?<i style={{ color: "red" }}>*</i>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div style={{ marginLeft: "10px" }}>
                            <Checkbox
                            disabled={localStorage.getItem("payment_status") == 1}
                                checked={agreement_check}
                                onChange={(e) => e.target.checked ? set_agreement_check(1) : set_agreement_check(0)}
                                style={{ color: "#626262", fontWeight: "500", textTransform: "none" }}>Yes, I understand and agree to the terms listed above.</Checkbox>
                            <br />
                            {errors?.agreement_check && <><span style={{ color: "red" }}><br />
                                {errors?.agreement_check[0]}</span></>}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <div className="input-box">
                                <label>
                                    Signature<i style={{ color: "red" }}>*</i>
                                </label>
                                {localStorage.getItem("payment_status") == 0 &&
                                <>
                                <div style={{ width: 402, height: 82, border: '1px solid' }}>
                                    <SignatureCanvas
                                        ref={(ref) => set_student_signature_ref(ref)}
                                        penColor='black'
                                        backgroundColor="rgba(255,255,255,1)"
                                        canvasProps={{ width: 400, height: 80, className: 'sigCanvas' }} />
                                </div>
                                {errors?.student_signature && <><span style={{ color: "red" }}><br />
                                    {errors?.student_signature[0]}</span></>}
                                {student_signature_empty_error && <><span style={{ color: "red" }}><br />
                                    {student_signature_empty_error}</span></>}
                                <br />
                                <Button style={{ marginTop: "8px" }} onClick={() => { student_signature_ref.clear(); set_student_signature(null); set_student_signature_empty_error('') }}>Clear</Button>
                                <Button style={{ marginLeft: "8px", marginTop: "8px" }} onClick={() => {
                                    if (!student_signature_ref.isEmpty()) {
                                        set_errors("");
                                        set_student_signature_empty_error("")
                                        set_student_signature(student_signature_ref.getTrimmedCanvas().toDataURL("image/jpeg/png"));
                                    }
                                    else {
                                        set_student_signature_empty_error("Please make your signature before save.")
                                    }
                                }}>Save</Button>
                                 </>
                                }
                                <br />
                                <br />

                                {
                                    student_signature ?

                                        <div>
                                            <img src={student_signature} />
                                        </div>

                                        :

                                        api_student_signature &&

                                        <div>
                                            <img src={api_student_signature} />
                                        </div>
                                }

                            </div>
                        </div>
                    </div>

                    {/* button */}
                    <div className="row">
                        <div className="col-1" style={{ marginRight: "15px" }}>
                            <div className="input-box">
                                {loader ? (
                                    <>
                                        <Button type="primary">
                                            <Spin
                                                indicator={
                                                    <LoadingOutlined
                                                        style={{
                                                            fontSize: "12px",
                                                            color: "#fff",
                                                            marginRight: "5px",
                                                        }}
                                                    />
                                                }
                                            />{" "}
                                            Previous
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Button onClick={() => navigate("/finance-declaration")} type="primary">
                                            Previous
                                        </Button>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="col-1">
                            <div className="input-box">
                                {loader ? (
                                    <>
                                        <Button type="primary" style={{ width: "100%" }}>
                                            <Spin
                                                indicator={
                                                    <LoadingOutlined
                                                        style={{
                                                            fontSize: "12px",
                                                            color: "#fff",
                                                            marginRight: "5px",
                                                        }}
                                                    />
                                                }
                                            />{" "}
                                            Next
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Button onClick={() => { AGREEMENT_DRAFT_NEXT(2) }} type="primary" style={{ width: "100%" }}>
                                            Next
                                        </Button>
                                    </>
                                )}
                            </div>
                        </div>
                        {localStorage.getItem("payment_status") == 0 &&
                        <div className="col-2">
                            <div className="input-box">
                                {loader ? (
                                    <>
                                        <Button type="primary" ghost>
                                            <Spin
                                                indicator={
                                                    <LoadingOutlined
                                                        style={{
                                                            fontSize: "12px",
                                                            color: "#fff",
                                                            marginRight: "5px",
                                                        }}
                                                    />
                                                }
                                            />{" "}
                                            Save as Draft
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Button onClick={() => { AGREEMENT_DRAFT_NEXT(1) }} type="primary" ghost>
                                            Save as Draft
                                        </Button>
                                    </>
                                )}
                            </div>
                        </div>
                        }
                    </div>
                </>
            }
        </>
    );

}

export default Agreement