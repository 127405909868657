/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button, Popover, Spin, Table } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ACADEMIC,
  SIS_STORAGE,
  sisDateFormat,
  STUDENT_ACADEMIC,
  STUDENT_PROFILE,
  STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING,
} from "../../apis/apis";
import { render } from "@testing-library/react";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  FilterFilled,
  InfoCircleFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import SisLoader from "../../widgets/loader";
import AcademicsTransferredCourseTable from "./Tables/academics_transferred_course_table";
import AcademicPaymentPlan from "./Modals/academics_payment_plan";
import { unit_selection_limit } from "../common-functions";

function MyAcademic() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [academics_payment_plan_modal, set_academics_payment_plan_modal] =
    useState(false);
  const [enrollments_students, set_enrollments_students] = useState("");
  const [table_data, set_table_data] = useState("");
  const [course_semester_registrations, set_course_semester_registrations] =
    useState("");
  const [total_credits, set_total_credits] = useState("");
  const [total_earn_credits, set_total_earn_credits] = useState("");

  const [loader, set_loader] = useState(true);
  const [selected_concentration,set_selected_concentration] = useState("");
  const [my_academic_details, set_my_academic_details] = useState("");
  const [attendances_summary, set_attendances_summary] = useState("");
  const [programme_progress, set_programme_progress] = useState("");
  const [current_course, set_current_course] = useState("");
  const [semester_id, set_semster_id] = useState("");
  const [calendar_id, set_calendar_id] = useState("");
  const [semester_name, set_semester_name] = useState("");
  const [calendar, set_calendar] = useState("");
  const [program, set_program] = useState("");
  const [basic_information, set_basic_information] = useState("");
  const [semester_registration, set_semester_registration] = useState("");
  const [
    students_programmes_registrations,
    set_students_programmes_registrations,
  ] = useState("");
  const [transferred_course_list, set_transferred_course_list] = useState([]);
  const [payment_plan, set_payment_plan] = useState([]);
  const [payment_status, set_payment_status] = useState("");
  const [student_final_bill, set_student_final_bill] = useState({});
  const [upfront_amount_payment_status, set_upfront_amount_payment_status] =
    useState("");
  const [units_selected, set_units_selected] = useState("");
  const [general_eduction, set_general_eduction] = useState([]);
  const [progrma_concentration, set_progrma_concentration] = useState([]);
  const [upfront_amount, set_upfront_amount] = useState("");
  const [remaining_amount, set_remaining_amount] = useState("");

  const [
    upfront_amount_payment_indent_json,
    set_upfront_amount_payment_indent_json,
  ] = useState("");
  const [submit_button_status_message, set_submit_button_status_message] =
    useState("");

  const get_timing = (data, selected_days) => {
    return (
      <>
        {selected_days.map((item) => (
          <>
            {item?.start_time && (
              <>
                {data?.timing ? (
                  <>
                    <span
                      style={{
                        width: "110px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "12px",
                      }}
                    >
                      {item?.value?.slice(0, 2).toUpperCase()}{" "}
                      {item?.start_time} - {item?.end_time}
                    </span>
                    <br />
                  </>
                ) : (
                  <>
                    <span style={{ color: "red" }}>Not Available</span>
                  </>
                )}
              </>
            )}
          </>
        ))}
      </>
    );
  };

  const columns = [
    {
      title: "Courses",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.course_title.localeCompare(b.course_title),
    },
    {
      title: "Courses Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Total Credits",
      dataIndex: "units",
      key: "units",
    },
    {
      title: "Attempted Credits",
      render: (text, record) => {
        return (
          <>
            {record?.course_semester_registration?.given_credits}
          </>
        );
      },
    },
    {
      title: "Room",
      render: (text, record) => {
        return (
          <>
            {record?.rooms?.room_name} ({record?.rooms?.room_number})
          </>
        );
      },
    },
    {
      title: "Timing",
      render: (text, record) => {
        var timingData = record?.timing ? JSON.parse(record.timing) : null;
        return <>{timingData && get_timing(record, timingData)}</>;
      },
    },
    {
      title: "Faculty",
      render: (text, record) => {
        return (
          <>
            <span style={{ textTransform: "capitalize" }}>
              {record?.faculty?.honorific} {record?.faculty?.name}
            </span>{" "}
          </>
        );
      },
    },
    {
      title: "Course Status",
      render: (text, record) => {
        return (
          <>
            <span style={{ textTransform: "capitalize" }}>
              {record?.course_semester_registration?.course_status == 1 && (
                <>Not Registered</>
              )}
              {record?.course_semester_registration?.course_status == 2 && (
                <>Registered</>
              )}
              {record?.course_semester_registration?.course_status === 3 && (
                <>Completed</>
              )}
              {record?.course_semester_registration?.course_status === 4 && (
                <>Withdrawn</>
              )}
              {record?.course_semester_registration?.course_status === 5 && (
                <>Graded</>
              )}
              {record?.course_semester_registration?.course_status === 6 && (
                <>Repeated</>
              )}
              {record?.course_semester_registration?.course_status === 7 && (
                <>Partial Completed</>
              )}
            </span>{" "}
          </>
        );
      },
    },
  ];
  useEffect(() => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    set_semster_id(USER_DATA.current_semester);
    set_calendar_id(USER_DATA.calendar_id);
    VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING();
  }, []);
  const VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("student_id", USER_DATA.id);
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("semester_id", USER_DATA.current_semester);
    FORM_DATA.append("acad_calender_id", USER_DATA.calendar_id);
    const VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE =
      await STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING(FORM_DATA);
    if (
      VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
        ?.status
    ) {
      set_programme_progress(VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
        ?.programme_progress);
      set_attendances_summary(VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
        ?.attendances_summary)
      set_selected_concentration( VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
        ?.selected_concentration);
      set_progrma_concentration(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.progrma_concentration
      );
      set_general_eduction(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.general_eduction
      );
      set_basic_information(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.basic_information
      );
      set_students_programmes_registrations(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.students_programmes_registrations
      );
      set_semester_registration(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.semester_registration
      );
      set_semester_name(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.semester?.title
      );
      set_calendar(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.calendar
      );
      set_program(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.program
      );
      set_transferred_course_list(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.transferred_course_list
      );
      set_payment_plan(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.payment_plans
      );
      if (
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.student_final_bill?.payment_status == 1
      ) {
        set_payment_status("Not Paid");
      }
      if (
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.student_final_bill?.payment_status == 2
      ) {
        set_payment_status("Paid");
      }
      if (
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.student_final_bill?.payment_status == 3
      ) {
        set_payment_status("Partially Paid");
      }
      set_student_final_bill(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.student_final_bill
      );
      set_upfront_amount_payment_status(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.student_final_bill?.upfront_amount_payment_status == 1
          ? true
          : false
      );

      if (
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.student_final_bill?.upfront_amount_payment_status
      ) {
        set_upfront_amount_payment_indent_json(
          JSON.parse(
            VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
              ?.student_final_bill?.upfront_amount_payment_indent_json
          )
        );
      }
      if (
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.student_final_bill
      ) {
        set_upfront_amount(
          VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
            ?.student_final_bill?.upfront_amount
        );
      }
      const totalBill =
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.total_billings || 0;
      const upfrontAmount =
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.student_final_bill?.upfront_amount || 0;

      // Calculate total paid amount from payment plans
      const paymentPlans =
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.payment_plans || [];

      const totalPaidAmount = paymentPlans
        .filter((plan) => plan.paid == 1) // Only include plans where paid is 1
        .reduce((acc, plan) => acc + parseFloat(plan.amount), 0); // Sum the amounts

      // Calculate remaining amount
      const remainingAmount = totalBill - upfrontAmount - totalPaidAmount;

      // Set the remaining amount
      set_remaining_amount(remainingAmount);
      set_units_selected(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.student_selected_units
      );
      const Button_Status = unit_selection_limit(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.student_selected_units,
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.semester_registration,
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.basic_information,
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.students_programmes_registrations,
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.program
      );
      set_submit_button_status_message(
        Button_Status?.message ? Button_Status?.message : ""
      );
      set_loader(false);
    } else {
      set_loader(false);
    }
  };
  const intToLocalDate = (seconds) => {
    let date = new Date(seconds * 1000);
    return date.toLocaleString(); // Returns date and time in local format
  };

  return (
    <>
      {loader ? (
        <SisLoader />
      ) : (
        <>
          <div>
            <h3
              className="first-letter-capitalize"
              style={{ color: "#999", padding: "20px 0" }}
            >
              My Academic Details
            </h3>
          </div>
          <div className="row">
            <div
              className="col-3 "
              style={{ display: "flex", flexDirection: "column" }}
            >
              <p className="first-letter-capitalize">Student Type</p>
              <h3>
                {students_programmes_registrations?.student_type
                  ? students_programmes_registrations?.student_type
                  : "N/A"}
              </h3>
            </div>
            <div
              className="col-3"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <p className="first-letter-capitalize">Matriculation Semester</p>
              <h3>{semester_name ? semester_name : "N/A"}</h3>
            </div>
            <div
              className="col-3"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <p className="first-letter-capitalize">School Decision</p>
              <h3>
                {basic_information?.school_decision
                  ? basic_information?.school_decision
                  : "N/A"}
              </h3>
            </div>
            <div
              className="col-3"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <p className="first-letter-capitalize">Entering Classification</p>
              <h3>
                {students_programmes_registrations?.entering_classification
                  ? students_programmes_registrations?.entering_classification
                  : "N/A"}
              </h3>
            </div>
          </div>
          <br />
          <div className="row">
            <div
              className="col-3"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <p className="first-letter-capitalize">Academic Year</p>
              <h3>{calendar?.title ? calendar?.title : "N/A"}</h3>
            </div>
            <div
              className="col-3"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <p className="first-letter-capitalize">Program/ Degree</p>
              <h3>{program?.title ? program?.title : "N/A"}</h3>
            </div>
            <div className="col-3" style={{ display: "flex", flexDirection: "column" }}>
              <p className="first-letter-capitalize">Concentration</p>
              <h3>{selected_concentration?.title ? selected_concentration?.title : 'N/A'}</h3>
            </div>
            <div
              className="col-3"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <p className="first-letter-capitalize">Program Status</p>
              <h3>
                {students_programmes_registrations?.programme_status
                  ? students_programmes_registrations?.programme_status
                  : "N/A"}
              </h3>
            </div>
          </div>
          <br />
          <hr />

          {student_final_bill ? (
            <>
              {/* <hr style={{color:"#999", margin:"70px 0 20px 0"}}/> */}
              {transferred_course_list.length > 0 && (
                <AcademicsTransferredCourseTable
                  transferred_course_list={transferred_course_list}
                />
              )}
              <div>
                <h3
                  className="first-letter-capitalize"
                  style={{ color: "#999", padding: "20px 0" }}
                >
                  Attendance Summary
                </h3>
              </div>
              <div
                className="first-letter-capitalize"
                style={{ color: "#999" }}
              >
                For the
              </div>
              <h4 style={{ color: "#777" }}>
                {attendances_summary?.calendar_title}
              </h4>
              <br></br>
              <div className="attendance-summary">
                <div className="attendance-item">
                  <div className="attendance-label first-letter-capitalize">
                    Absent unexcused
                  </div>
                  <div className="attendance-value">
                    {attendances_summary?.absent_unexcused}
                  </div>
                </div>
                <div className="attendance-item">
                  <div className="attendance-label first-letter-capitalize">
                    Absent Excused
                  </div>
                  <div className="attendance-value">
                    {attendances_summary?.absent_excused}
                  </div>
                </div>
                <div className="attendance-item">
                  <div className="attendance-label first-letter-capitalize">
                    Tardy Excused
                  </div>
                  <div className="attendance-value">
                    {attendances_summary?.tardy_excused}
                  </div>
                </div>
              </div>
              <br></br>
              <h3
                className="first-letter-capitalize"
                style={{ color: "#8E8E8E" }}
              >
                Program Progress
              </h3>
              <br></br>
              <div className="programme-attendance-summary">
                <div className="programme-attendance-item">
                  <div className="programme-attendance-label first-letter-capitalize">
                    Credit Earned
                  </div>
                  <div className="programme-attendance-value ">
                    {programme_progress?.credit_earned}
                  </div>
                </div>
                <div className="programme-attendance-item">
                  <div className="programme-attendance-label first-letter-capitalize">
                    In Progress
                  </div>
                  <div className="attendance-value">
                    {programme_progress?.in_progress}
                  </div>
                </div>
                <div className="programme-attendance-item">
                  <div className="programme-attendance-label first-letter-capitalize">
                    Credit Remaining
                  </div>
                  <div className="programme-attendance-value">
                    {programme_progress?.credit_remain}
                  </div>
                </div>
                <div className="programme-attendance-item">
                  <div className="programme-attendance-label first-letter-capitalize">
                    Total Credit
                  </div>
                  <div className="programme-attendance-value">
                    {programme_progress?.table_credit}
                  </div>
                </div>
              </div>
              <br></br>
              <h3
                className="first-letter-capitalize"
                style={{ color: "#8E8E8E" }}
              >
                Current Semester{" "}
                {my_academic_details?.matriculation_semester_title
                  ? my_academic_details?.matriculation_semester_title
                  : " "}
              </h3>
              <br />
              <div>
                <div className="theme-content-left-head">
                  <h4>Units selected for this semester : {units_selected}</h4>
                </div>
                <br />
              </div>
              {!student_final_bill?.upfront_amount_payment_status ? (
                <>
                  <div className="Lu_sis_">
                    <div
                      className="theme-content-head"
                      style={{ marginBottom: "0px" }}
                    >
                      <div className="col-4" style={{ paddingLeft: "0px" }}>
                        <div className="theme-content-left-head">
                          <span style={{ paddingRight: "10px" }}>
                            {" "}
                            <InfoCircleFilled
                              style={{
                                color: "#900000",
                                fontSize: "18px",
                                fontWeight: "bold",
                              }}
                            />
                          </span>
                          <span>
                            <b>Your bill is generated</b>
                          </span>
                          <p style={{ marginLeft: "27px" }}>
                            Please pay to complete the registartion.
                          </p>
                          <p style={{ marginLeft: "27px" }}>
                            Last day for registartion is 18 August 2024
                          </p>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="theme-content-left-head">
                          <Button
                            type="primary"
                            style={{ height: "40px" }}
                            onClick={() =>
                              navigate(
                                "/academics-billing/" +
                                  btoa(semester_id) +
                                  "/" +
                                  btoa(calendar_id) +
                                  "/" +
                                  upfront_amount
                              )
                            }
                          >
                            Pay Bill
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="Lu_sis_">
                    <div
                      className="theme-content-head"
                      style={{ marginBottom: "0px" }}
                    >
                      <div className="col-10" style={{ paddingLeft: "0px" }}>
                        <div className="theme-content-left-head">
                          <span style={{ paddingRight: "10px" }}>
                            {" "}
                            <CheckCircleOutlined
                              style={{
                                color: "#0f0",
                                fontSize: "18px",
                                fontWeight: "bold",
                              }}
                            />
                          </span>
                          <span>
                            <b>Upfront amount successfully received.</b> 
                            </span>
                            <br/><br/>
                            <span>
                            Request Date :{" "}
                            <b>
                            {sisDateFormat(
                              student_final_bill.upfront_payment_date
                            )}
                            </b>
                            </span>
                            <span style={{marginLeft:"15px"}}>
                            Paid Date :{" "}
                            <b>
                            {intToLocalDate(
                              upfront_amount_payment_indent_json?.created
                            )}
                            </b>
                            </span>
                            <span style={{marginLeft:"15px"}}>
                            Txn :{" "}
                            {
                              upfront_amount_payment_indent_json?.balance_transaction
                            }{" "}
                          </span>
                          <br/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br/>
                  <div className="Lu_sis_">
                    <div
                      className="row"
                      style={{ marginLeft: 0, marginBottom: "10px" }}
                    >
                      <div
                        className="theme-content-head"
                        style={{ marginBottom: "0px" }}
                      >
                        <div className="theme-content-left-head">
                          <span style={{ paddingRight: "10px" }}>
                            {" "}
                            <CheckCircleOutlined
                              style={{
                                color: "#0f0",
                                fontSize: "18px",
                                fontWeight: "bold",
                              }}
                            />
                          </span>
                          <span>
                            <b>
                              You are successfully registered in{" "}
                              {semester_name ? semester_name : " "}
                            </b>
                          </span>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <h3
                      className="first-letter-capitalize"
                      style={{ color: "#8E8E8E" }}
                    >
                      Payment
                    </h3>
                    <div className="row" style={{ marginLeft: 0 }}>
                      <div
                        className="theme-content-head"
                        style={{ marginBottom: "0px" }}
                      >
                        <div className="col-2" style={{ paddingLeft: "0px" }}>
                          <div className="theme-content-left-head">
                            <p style={{ marginBottom: "5px" }}>
                              Payment Status
                            </p>
                            <span>
                              <b>{payment_status}</b>
                            </span>
                          </div>
                        </div>
                        <div className="col-5" style={{ paddingLeft: "0px" }}>
                          <div className="theme-content-left-head">
                            <p style={{ marginBottom: "5px" }}>
                              Remaining amount to be paid in{" "}
                              <span
                                style={{
                                  color: "#1e90ff",
                                  fontWeight: 500,
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  set_academics_payment_plan_modal(true)
                                }
                              >
                                {payment_plan?.length} months payment plan
                              </span>
                            </p>
                            <span>
                              <b>${remaining_amount}</b>
                            </span>
                          </div>
                        </div>
                        <div className="col-5">
                          <div className="theme-content-left-head">
                            {payment_plan?.map((payment_data, index) => (
                              <p key={index} style={{ marginBottom: "5px" }}>
                              <span>Payment : 
                                <b> {payment_data?.payment_name}</b>{" "}</span>
                                <span>
                                Payment Date : <b> {sisDateFormat(payment_data?.payment_date)}</b></span>
                                <span>
                                  {" "}
                                  Status :
                                  <b> {payment_data?.paid == 1
                                      ? "Paid"
                                      : "Pending"}
                                  </b>
                                </span>
                                <span></span>
                              </p>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br></br>
                </>
              )}
              {general_eduction?.length > 0 ? (
                <div
                  style={{
                    border: "1px solid #E1E1E1",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <div
                    className="container"
                    style={{ color: "#3B82F6", padding: "10px 0" }}
                  >
                    <div className="col-6 first-letter-capitalize">
                      General Eduction
                    </div>
                    {/* <div className="col-6">
                      {programme_progress?.in_progress}
                    </div> */}
                  </div>
                  <div>
                    {loader ? (
                      <SisLoader />
                    ) : (
                      <Table
                        columns={columns}
                        pagination={false}
                        dataSource={general_eduction}
                      />
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
              <br />
              {progrma_concentration?.length > 0 ? (
                <div
                  style={{
                    border: "1px solid #E1E1E1",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <div
                    className="container"
                    style={{ color: "#3B82F6", padding: "10px 0" }}
                  >
                    <div className="col-6 first-letter-capitalize">
                      Concentration
                    </div>
                    {/* <div className="col-6">
                      {programme_progress?.in_progress}
                    </div> */}
                  </div>
                  <div>
                    {loader ? (
                      <SisLoader />
                    ) : (
                      <Table
                        columns={columns}
                        pagination={false}
                        dataSource={progrma_concentration}
                      />
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {submit_button_status_message && (
                <>
                  <div>
                    <div className="theme-content-left-head">
                      <h3>Course Selection</h3>
                      <h4 style={{ marginTop: "10px", color: "#555" }}>
                        {submit_button_status_message}
                      </h4>
                    </div>
                  </div>
                  <br />
                </>
              )}
            </>
          ) : (
            <>
              {semester_registration && (
                <>
                  <div className="Lu_sis_">
                    <div
                      className="theme-content-head"
                      style={{ marginBottom: "0px" }}
                    >
                      <div className="theme-content-left-head">
                        <span style={{ paddingRight: "10px" }}>
                          {" "}
                          <InfoCircleFilled
                            style={{
                              color: "#900000",
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          />
                        </span>
                        <span>
                          <b>You have submitted the courses registration</b>
                        </span>
                        <p style={{ marginLeft: "27px" }}>
                          Your billing is being generated.
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <p
                style={{
                  margin: "50px 0 0 0",
                  textAlign: "center",
                  color: "black",
                  fontSize: "16px",
                  fontWeight: 500,
                }}
              >
                You are not registered for any course in this semester
              </p>
              <br />
              <div
                className="row"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="col-4" style={{ width: "200px" }}>
                  <div className="input-box">
                    <Button
                      type="primary"
                      style={{ height: "40px" }}
                      onClick={() =>
                        navigate(
                          "/self-semester-registration/" + btoa(semester_id)
                        )
                      }
                    >
                      Register For Courses
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
      <AcademicPaymentPlan
        academics_payment_plan_modal={academics_payment_plan_modal}
        set_academics_payment_plan_modal={set_academics_payment_plan_modal}
        payment_plan={payment_plan}
      />
    </>
  );
}

export default MyAcademic;
