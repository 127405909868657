import { Table } from "antd";
import { sisDateFormat } from "../../../apis/apis";



const AcademicsTransferredCourseTable = ({transferred_course_list}) => {

    const transferred_course_column = [

        {
          title: "LU Courses",
          render: (text, record) => {
              return <><span>Business Principles</span></>;
          },
          sorter: (a, b) => a.transferred_course.localeCompare(b.transferred_course),
      },
      {
          title: "Courses Code",
          render: (text, record) => {
              return <><span>{record?.course_code}</span></>;
          },
      },
    
      {
          title: "Credit{Auto Fill}",
          render: (text, record) => {
              return <><span >{record?.credit_attempted.toFixed(1)}</span></>;
          },
      },
      {
          title: "Transferred Course",
          render: (text, record) => {
              return <><span >{record?.transferred_course}</span></>;
          },
      },
      {
          title: "Transferred Institution",
          render: (text, record) => {
              return <>
                  <span>{record?.transferred_institution}</span>
              </>;
          },
      },
      {
        title: "Begin Date",
        render: (text, record) => {
            return <>
                <span>{sisDateFormat(record?.begin_date)}</span>
            </>;
        },
    },
    {
      title: "End Date",
      render: (text, record) => {
          return <>
              <span>{sisDateFormat(record?.end_date)}</span>
          </>;
      },
    },
      {
        title: "Credit Attempted",
        render: (text, record) => {
            return <><span >{record?.credit_attempted.toFixed(1)}</span></>;
        },
    },
    {
        title: "Credit Earned",
        render: (text, record) => {
            return <><span >{record?.credit_earn.toFixed(1)}</span></>;
        },
    },
    
      ]
    
return(
    <>
     <div>
          <h3 className="first-letter-capitalize" style={{color:"#999",padding:"20px 0"}}>Transferred Courses</h3>
          </div>
          <Table columns={transferred_course_column} dataSource={transferred_course_list} pagination={false}/>
    </>
);
    
}

export default AcademicsTransferredCourseTable;