import React, { useEffect, useState } from 'react'
import GradeTable from './gradeTable';

const SemesterWiseGradeView = ({ item }) => {
  return (
    <>
    {
      (item?.transferred_course?.length > 0 ||  item?.university_course?.length) > 0 && <>
      {/* <br></br> */}
      {/* <div style={{border:"1px solid #999", padding:"20px"}}> */}
      {/* <h3 style={{ color: "#3B82F6" }}>{item?.semester?.title}</h3> */}
      {
        item?.transferred_course?.length > 0 ? <>
          <br></br>
          <div className='first-letter-capitalize'  style={{fontWeight:"700"}}>Transferred Course</div>
          <GradeTable transferred_course_item={item?.transferred_course}/>
        </>
          : <></>
      }
      {
        item?.university_course?.length > 0 ? <>
          <br></br>
          <h3 style={{ color: "#3B82F6" }}>{item?.semester?.title}</h3>
          <br></br>
          <h3 className='first-letter-capitalize' style={{ color: "#3B82F6" }}>Lincoln University</h3>
          <GradeTable university_course_item={item?.university_course}/>
        </>
          : <></>
      }
      {/* </div> */}
      </>
    }
      
    </>
  )
}

export default SemesterWiseGradeView