import React, { useEffect, useState } from "react";
import "./student.css";
import { Popover, Table } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ACADEMIC, SIS_STORAGE, STUDENT_PROFILE } from "../../../apis/apis";
import { render } from "@testing-library/react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import SisLoader from "../../../widgets/loader";

function Academic() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [enrollments_students, set_enrollments_students] = useState("");
  const [course_semester_registrations, set_course_semester_registrations] =
    useState("");
  const [total_credits, set_total_credits] = useState("");
  const [total_earn_credits, set_total_earn_credits] = useState("");

  const [loader, set_loader] = useState(true);
  const [remaining_credits, set_remaining_credits] = useState("");

  const location = useLocation();

  const get_timing = (data, selected_days) => {
    return (
      <>
        {selected_days.map((item) => (
          <>
            {item?.start_time && (
              <>
                {data?.timing ? (
                  <>
                    <span
                      style={{
                        width: "110px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "12px",
                      }}
                    >
                      {item?.value?.slice(0, 2).toUpperCase()}{" "}
                      {item?.start_time} - {item?.end_time}
                    </span>
                    <br />
                  </>
                ) : (
                  <>
                    <span style={{ color: "red" }}>Not Available</span>
                  </>
                )}
              </>
            )}
          </>
        ))}
      </>
    );
  };
  // Course Status
  const course_status = (data) => {
    return (
      <>
        <div className="input-box">
        {data?.course_status === 2 && (
                    <>Registered</>
                  )}
                  {data?.course_status === 3 && (
                    <>Completed</>
                  )}
                  {data?.course_status === 4 && (
                    <>Withdrawn</>
                  )}
                  {data.course_status === 5 && (
                    <>Graded</>
                  )}
                  {data?.course_status === 6 && (
                    <>Repeated</>
                  )}
                  {data?.course_status === 7 && (
                    <>Partial Completed</>
                  )}
        </div>
      </>
    );
  };
  const columns = [
    {
      title: "Course",
      render: (text, record) => {
        return (
          <>
            <div style={{ minWidth: "350px" }}>
              <span
                style={{
                  fontSize: "12px",
                  paddingLeft: "22px",
                  course: "pointer",
                }}
              >
                {record.course_title}
                {record.perquisite_courses_details && (
                  <>
                    <Popover
                      content={
                        <>
                          Prerequisites:
                          {record?.perquisite_courses_details.map((p_item) => (
                            <>
                              <span
                                style={{ fontWeight: "bold", margin: "2px" }}
                              >
                                {p_item.code},{" "}
                              </span>
                            </>
                          ))}
                          and at least two concentration course
                        </>
                      }
                      title={
                        <>
                          <ExclamationCircleOutlined
                            style={{ color: "blue" }}
                          />{" "}
                          Prerequisite requirement
                        </>
                      }
                      trigger="hover"
                    >
                      <i title="Perquisite course">P</i>
                    </Popover>
                  </>
                )}
                {record?.transferred_course && (
                  <>
                    <i
                      title="Transferred course"
                      style={{
                        background: "black",
                        marginLeft: "10px",
                        padding: "2px 7px",
                        color: "#fff",
                        borderRadius: "50px",
                      }}
                    >
                      T
                    </i>
                  </>
                )}
              </span>
            </div>
          </>
        );
      },
    },
    {
      title: "Course Code",
      dataIndex: "course_code",
      key: "course_code",
    },
    {
      title: "Total Credits",
      dataIndex: "course_units",
      key: "course_units",
    },
    {
      title: "Given Credits",
      dataIndex: "given_credits",
      key: "given_credits",
    },
    {
      title: "Room",
      render: (text, record) => {
        return (
          <>
            {record?.room_name} {record?.room_number}
          </>
        );
      },
    },
    {
      title: "timing",
      render: (text, record) => {
        var timingData = record?.timing ? JSON.parse(record.timing) : null;
        return <>{timingData && get_timing(record, timingData)}</>;
      },
    },
    {
      title: "Faculty",
      dataIndex: "faculty_name",
      key: "faculty_name",
    },
    {
      title: "Course Status",
      render: (text, record) => {
        return <>{course_status(record)}</>;
      },
    },
  ];
  const VIEW_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("student_id", atob(id));
    FORM_DATA.append("token", USER_DATA.token);

    const EDIT_API_RESPONSE = await ACADEMIC(FORM_DATA);

    if (EDIT_API_RESPONSE?.data?.status) {
      set_enrollments_students(EDIT_API_RESPONSE?.data?.enrollments_students);
      set_course_semester_registrations(
        EDIT_API_RESPONSE?.data?.course_semester_registrations
      );
      set_total_credits(EDIT_API_RESPONSE?.data?.total_credits);
      set_total_earn_credits(EDIT_API_RESPONSE?.data?.total_earn_credits);
      set_remaining_credits(EDIT_API_RESPONSE?.data?.remaining_credits);
      set_loader(false)
    }
  };
  useEffect(() => {
    VIEW_API();
  }, [loader]);
  return (
    <>
      {loader ? (
        <SisLoader />
      ) : (
        <>
          <div className="sis-tab-menu">
            <span onClick={() => navigate("/student-detail/" + id)}>
              Profile
            </span>
            <span
              className="active"
              onClick={() => navigate("/student-academy/" + id)}
            >
              Academic
            </span>
            <span>Grades</span>
          </div>
          <div className="row">
            <div
              className="col-2"
              style={{ maxWidth: "100px", minWidth: "100px" }}
            >
              <img
                style={{
                  width: "78px",
                  height: "78px",
                  borderRadius: "14px",
                  marginleft: "-12px",
                }}
                src={
                  SIS_STORAGE +
                  "/enrollments/" +
                  enrollments_students?.enrollment_image
                }
                alt="Profile Picture"
              />
            </div>
            <div className="col-8">
              <h3 style={{ marginBottom: "10px" }}>
                {enrollments_students?.enrollment_first_name}{" "}
                {enrollments_students?.enrollment_middle_name !== "null" &&
                  enrollments_students?.enrollment_middle_name &&
                  enrollments_students?.enrollment_middle_name}{" "}
                {enrollments_students?.enrollment_last_name}{" "}
              </h3>
              <div className="row">
                <div className="col-3">
                  <p>Application number</p>
                  <h4> {enrollments_students?.enrollment_application_no}</h4>
                </div>
                <div className="col-3">
                  <p>Student ID</p>
                  <h4> {enrollments_students?.enrollment_student_id}</h4>
                </div>
              </div>
            </div>
          </div>
          <h3 style={{ color: "#8E8E8E", margin: "15px 0px" }}>
            Academic Details
          </h3>
          <div class="container">
            <div class="frame-427318317">
              <div
                style={{
                  color: "grey",
                }}
                class="student-type"
              >
                Student type
              </div>
              <span class="india1">{enrollments_students?.student_type}</span>
            </div>
            <div class="frame-427318262">
              <div
                style={{
                  color: "grey",
                }}
                class="student-type1"
              >
                Programme/Degree
              </div>
              <span class="india">{enrollments_students?.programme_title}</span>
            </div>
            <div class="frame-427318263">
              <div
                style={{
                  color: "grey",
                }}
                class="student-type2"
              >
                Programme status
              </div>
              <span class="india2">
                {enrollments_students?.programme_status}
              </span>
            </div>
            <div class="frame-427318265">
              <div
                style={{
                  color: "grey",
                }}
                class="student-type3"
              >
                Matriculation Semester
              </div>
              <span class="india3">
                {enrollments_students?.matriculation_semester}
              </span>
            </div>
          </div>
          <br></br>
          <h3 style={{ color: "#8E8E8E", margin: "15px 0px" }}>
            Programme Details
          </h3>
          <div className="form-step-box" style={{ float: "left" }}>
            <div className={"form-step active"}>
              <div className="form-step-line"></div>
              <span>{total_credits}</span>
              <h3>Total Credits </h3>
            </div>
            <div className={"form-step running"}>
              <div className="form-step-line"></div>
              <span>{total_earn_credits}</span>
              <h3>Earned</h3>
            </div>
            <div className={"form-step running"}>
              <span>{remaining_credits}</span>
              <h3>Remaining</h3>
            </div>
          </div>

          <div className="common-form">
            {/* <div className='tablehedding'>
                    <span style={{ marginTop: '30px', color: 'blue' }}>Current courses</span>
                    <span className='credit' style={{ color: 'blue', marginTop: '36px', }}>09 credits</span>
                </div> */}

            <Table
              columns={columns}
              dataSource={course_semester_registrations}
            />
          </div>
        </>
      )}
    </>
  );
}

export default Academic;
