import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  Button,
  Checkbox,
  Input,
  Radio,
  Select,
  Spin,
  Table,
  notification,
  DatePicker,
  Space,
  Divider,
  List,
  Typography,
} from "antd";
import { InputNumber } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import {
  LoadingOutlined,
  CloseOutlined,
  CloudUploadOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import {
  ADD_ENROLLMENT,
  CATEGORIES_LIST,
  VIEW_ENROLLMENT,
  PROGRAMS_LIST,
  STUDENTS_LIST,
  SIS_STORAGE,
  STUDENTS_ENROLLMENTS_LIST,
  STUDENTS_ENROLLMENTS_VIEW,
  EDIT_ENROLLMENT,
  CALENDARS_LIST,
  ACADEMIC_SEMESTER,
} from "../../apis/apis";
import SisLoader from "../../widgets/loader";
import Item from "antd/es/list/Item";
import { useNavigate, useParams } from "react-router-dom";
import countryList from "react-select-country-list";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import "./style.css";

import StudentImage from "../../images/user.png";

dayjs.extend(customParseFormat);

const AddEnrollment = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loader, set_loader] = useState(false);
  const [errors, set_errors] = useState([]);
  const [entering_year, set_entering_year] = useState("");
  const [matriculation_semester_id, set_matriculation_semester_id] =
    useState("");
  const [confer_date, set_confer_date] = useState("");
  const [status_id, set_status_id] = useState("");
  const [entering_class, set_entering_class] = useState("");
  const [programme_list, set_programme_list] = useState([]);
  const [student_list, set_student_list] = useState([]);
  const [student_info, set_student_info] = useState([]);
  const [disableButton, setDisableButton] = useState(true);
  const [table_list, set_table_list] = useState([]);
  const [page_loader, set_page_loader] = useState(true);
  const [enroll_id, set_enroll_id] = useState("");
  const [url_image, set_url_image] = useState("");

  // Form Data
  const [image, set_image] = useState("");
  const [first_name, set_first_name] = useState("");
  const [middle_name, set_middle_name] = useState("");
  const [last_name, set_last_name] = useState("");
  const [student_id, set_student_id] = useState("");
  const [application_no, set_application_no] = useState("");

  const [programme_intended, set_programme_intended] = useState("");
  const [programme_status, set_programme_status] = useState("");

  const [student_status, set_student_status] = useState("");
  const [matriculation_semester, set_matriculation_semester] = useState("");
  const [matriculation_date, set_matriculation_date] = useState("");
  const [entering_classification, set_entering_classification] = useState("");
  const [student_type, set_student_type] = useState("");
  const [programme_id, set_programme_id] = useState("");
  const [student_status_check, set_student_status_check] = useState();
  // VIEW API
  const VIEW_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("id", id);
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("image", image);
    const VIEW_API_RESPONSE = await STUDENTS_ENROLLMENTS_VIEW(FORM_DATA);
    if (VIEW_API_RESPONSE?.data?.status) {
      if (VIEW_API_RESPONSE?.data?.enrollments) {
        set_application_no(VIEW_API_RESPONSE.data.enrollments.application_no);
        set_image(VIEW_API_RESPONSE.data.enrollments.image);
        set_enroll_id(VIEW_API_RESPONSE.data.enrollments.id);
        set_student_id(VIEW_API_RESPONSE.data.enrollments.student_id);
        set_first_name(VIEW_API_RESPONSE.data.enrollments.first_name);
        set_middle_name(VIEW_API_RESPONSE.data.enrollments.middle_name);
        set_last_name(VIEW_API_RESPONSE.data.enrollments.last_name);
       
        set_programme_intended(
          VIEW_API_RESPONSE.data.enrollments.programme_intended
        );
        set_student_status(VIEW_API_RESPONSE.data.enrollments.student_status);
        set_student_status_check(VIEW_API_RESPONSE.data.enrollments.student_status);
      }
      if (VIEW_API_RESPONSE.data.programme) {
        set_student_type(VIEW_API_RESPONSE.data.programme.student_type)
        set_matriculation_semester(
          VIEW_API_RESPONSE?.data?.programme?.matriculation_semester
        );
        set_matriculation_date(
          VIEW_API_RESPONSE?.data?.programme?.matriculation_date
        );
        set_confer_date(VIEW_API_RESPONSE?.data?.programme?.confer_date);
        set_programme_status(
          VIEW_API_RESPONSE?.data?.programme?.programme_status
        );
        set_entering_classification(
          VIEW_API_RESPONSE?.data?.programme?.entering_classification
        );
        set_entering_year(VIEW_API_RESPONSE?.data?.programme?.entering_year);
      }
      set_page_loader(false);
    }
  };

  useEffect(() => {
    PROGRAMS_LIST_API();
    STUDENTS_API();
  }, []);

  const STUDENTS_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", id);
    FORM_DATA.append("image", image);
    FORM_DATA.append("first_name", first_name);
    FORM_DATA.append("middle_name", middle_name);
    FORM_DATA.append("last_name", last_name);
    FORM_DATA.append("student_id", status_id);
    FORM_DATA.append("student_status", student_status);
    FORM_DATA.append("programme_intended", programme_intended);
    const API_RESPONSE = await STUDENTS_ENROLLMENTS_LIST(FORM_DATA);
    // console.log(image)
    if (API_RESPONSE?.data?.status) {
      set_student_list(API_RESPONSE.data.student);
      VIEW_API(API_RESPONSE.data.student);
      set_loader(false);
    } else {
      set_loader(false);
    }
  };
  const PROGRAMS_LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", id);
    const API_RESPONSE = await PROGRAMS_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_programme_list(API_RESPONSE.data.programmes);
    } else {
      set_loader(false);
    }
  };

  // Samestart View API

  const SEMESTER_VIEW_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", id);
    const API_RESPONSE = await ACADEMIC_SEMESTER(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_table_list(API_RESPONSE.data.academic_semester);
      set_loader(false);
    } else {
      set_loader(false);
    }
  };
  useEffect(() => {
    PROGRAMS_LIST_API();
    STUDENTS_API();
    SEMESTER_VIEW_API();
  }, [loader]);

  const handleImageChange = (e) => {
    const files = e.target.files;
    if (
      (files[0].type === "image/jpeg" ||
        files[0].type === "image/jpg" ||
        files[0].type === "image/png") &&
      files[0].size < 2000001
    ) {
      set_url_image(files[0]);
    } else {
      notification.open({
        message: "Not Allowed",
        description:
          "Please check image File format must be .jpg or .png and Max. file size 2 Mb",
        icon: <SmileOutlined style={{ color: "red" }} />,
      });
    }
  };
  const handleRemoveImage = (e) => {
    set_url_image("");
  };

  //for disable button
  useEffect(() => {
    if (
      programme_id &&
      student_id &&
      entering_year &&
      matriculation_semester_id &&
      matriculation_date &&
      status_id &&
      entering_class
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [
    programme_id,
    student_id,
    entering_year,
    matriculation_semester_id,
    matriculation_date,
    confer_date,
    status_id,
    entering_class,
  ]);

  const ADD_API = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", id);
    FORM_DATA.append("image", image);
    FORM_DATA.append("first_name", first_name);
    FORM_DATA.append("middle_name", middle_name);
    FORM_DATA.append("last_name", last_name);
    FORM_DATA.append("student_id", student_id);
    FORM_DATA.append("student_status", student_status);
    FORM_DATA.append("programme_intended", programme_intended);
    FORM_DATA.append("entering_year", entering_year);
    FORM_DATA.append("matriculation_semester", matriculation_semester);
    FORM_DATA.append("matriculation_date", matriculation_date);
    FORM_DATA.append("confer_date", confer_date);
    FORM_DATA.append("programme_status", programme_status);
    FORM_DATA.append("programme_id", programme_id);
    FORM_DATA.append("student_type", student_type);
    FORM_DATA.append("entering_classification", entering_classification);
    const API_RESPONSE = await EDIT_ENROLLMENT(FORM_DATA);
    console.log(API_RESPONSE);
    if (API_RESPONSE.data.status) {
      notification.open({
        message: "Success!!",
        description: "Student Enrollment Successfully added.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      navigate("/enrollments-list");
    } else {
      set_errors(API_RESPONSE.data.errors);
      set_loader(false);
    }
  };

  return (
    <div>
      <div className="theme-content-head">
        <div className="theme-content-left-head">
          <h3>Register Student in Program</h3>
        </div>

        <div className="theme-content-right-head"></div>
      </div>
      <div className="common-form">
        {page_loader ? (
          <SisLoader />
        ) : (
          <>
            {errors?.try && (
              <>
                <span style={{ color: "red" }}>{errors?.try[0]}</span>
              </>
            )}
            {errors?.catch && (
              <>
                <span style={{ color: "red" }}>{errors?.catch[0]}</span>
              </>
            )}
            <div className="col-8" style={{ width: "100%" }}>
              {/* Basic Information */}
              <>
                <div className="theme-content-left-head">
                  <h3>Basic information</h3>
                </div>
                <div className="basic-information">
                  {/* Student Image */}
                  <div className="input-box" style={{ position: "relative" }}>
                    <label htmlFor="address">Image</label>
                    <br></br>
                    {/* <label className='upload-box'> <input type="file" id="upload-images" accept="image/*" style={{ height: "0px", overflow: "hidden" }} onChange={handleImageChange} /> <CloudUploadOutlined /> Upload Image</label> */}
                    {url_image ? (
                      <>
                        <div
                          className="image-box"
                          style={{
                            backgroundImage:
                              "url(" + URL.createObjectURL(url_image) + ")",
                          }}
                        ></div>
                        <button
                          className="image-remove"
                          onClick={() => handleRemoveImage()}
                        >
                          <CloseOutlined />
                        </button>
                      </>
                    ) : (
                      <>
                        {image && (
                          <>
                            <div
                              className="image-box"
                              style={{
                                backgroundImage:
                                  "url(" +
                                  SIS_STORAGE +
                                  "/enrollments/" +
                                  image +
                                  ")",
                              }}
                            ></div>
                          </>
                        )}
                      </>
                    )}

                    {errors?.image && (
                      <>
                        <span style={{ color: "red" }}>{errors?.image[0]}</span>
                      </>
                    )}
                  </div>

                  {/* First Name */}
                  <div className="input-box" style={{ width: "200px" }}>
                    <label>
                      First Name<i style={{ color: "red" }}>*</i>
                    </label>
                    <br></br>
                    <Input value={first_name} disabled />

                    {/* <Select
                                    placeholder="Select Student"
                                    style={{ width: '100%' }}
                                    onChange={(value) => { set_student_first_name(value); set_student_info(student_list.filter(obj => obj.id === value)) }}
                                    value={student_id}
                                    disabled
                                >
                                    {student_list.map((item) => {
                                        if (item.status === 1 && item.soft_delete === 0) {
                                            return (
                                                <Select.Option key={item.id} value={item.id}>
                                                    {`${item.first_name}`}
                                                </Select.Option>
                                            );
                                        }
                                    })}
                                </Select> */}

                    {errors?.student_id && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.student_id[0]}
                        </span>
                      </>
                    )}
                  </div>

                  {/* Middle Name */}
                  <div className="input-box" style={{ width: "200px" }}>
                    <label>
                      Middle Name<i style={{ color: "red" }}>*</i>
                    </label>
                    <br></br>
                    <Input value={middle_name === 'null' ? '' : middle_name} disabled />
                    {/* <Select
                                    placeholder="Select Student"
                                    style={{ width: '100%' }}
                                    onChange={(value) => { set_student_id(value); set_student_info(student_list.filter(obj => obj.id === value)) }}
                                    value={student_id}
                                    disabled

                                >
                                    {student_list.map((item) => {
                                        if (item.status === 1 && item.soft_delete === 0) {
                                            return (
                                                <Select.Option key={item.id} value={item.id}>
                                                    {` ${item.middle_name}`}
                                                </Select.Option>
                                            );
                                        }
                                    })}
                                </Select> */}

                    {errors?.student_id && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.student_id[0]}
                        </span>
                      </>
                    )}
                  </div>
                  {/* Last Name */}
                  <div className="input-box" style={{ width: "200px" }}>
                    <label>
                      Last Name<i style={{ color: "red" }}>*</i>
                    </label>
                    <br></br>
                    <Input
                      // onChange={(value) => { set_last_name(value); set_student_info(last_name.filter(obj => obj.id === value)) }}
                      value={last_name}
                      disabled
                    />
                    {/* <Select
                                    placeholder="Select Student"
                                    style={{ width: '100%' }}
                                    onChange={(value) => { set_last_name(value); set_student_info(last_name.filter(obj => obj.id === value)) }}
                                    value={last_name}
                                    disabled

                                >
                                    {student_list.map((item) => {
                                        if (item.status === 1 && item.soft_delete === 0) {
                                            return (
                                                <Select.Option key={item.id} value={item.id}>
                                                    {`${item.last_name}`}
                                                </Select.Option>
                                            );
                                        }
                                    })}
                                </Select> */}

                    {errors?.student_id && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.student_id[0]}
                        </span>
                      </>
                    )}
                  </div>
                  {/* Student ID */}
                  <div className="input-box" style={{ width: "200px" }}>
                    <label>
                      Student ID<i style={{ color: "red" }}>*</i>
                    </label>
                    <br></br>
                    <Input
                      // onChange={(value) => { set_last_name(value); set_student_info(last_name.filter(obj => obj.id === value)) }}
                      value={student_id}
                      disabled
                    />
                    {/* <Select
                      placeholder="Select Student"
                      style={{ width: "100%" }}
                      onChange={(value) => {
                        set_student_id(value);
                        set_student_info(
                          student_list.filter((obj) => obj.id === value)
                        );
                      }}
                      value={student_id}
                      disabled
                    /> */}

                    {errors?.student_id && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.student_id[0]}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </>
              <br />
              <hr />
              <br />

              {/* Program / Degree intended */}
              <>
                <div>
                  <div className="input-box">
                    <label>
                      Program/Degree intended<i style={{ color: "red" }}>*</i>
                    </label>
                    <br></br>
                    <Select
                      placeholder="--Select Programme--"
                      style={{ width: "40%" }}
                      onChange={(value) => {
                        set_programme_intended(value);
                      }}
                      value={programme_intended}
                      disabled
                    >
                      {programme_list.map((item) => {
                        if (item.status === 1 && item.soft_delete === 0) {
                          return (
                            <>
                              <Select.Option value={item.id}>
                                {item.title}
                              </Select.Option>
                            </>
                          );
                        }
                      })}
                    </Select>
                    {errors?.programme_id && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.programme_id[0]}
                        </span>
                      </>
                    )}
                  </div>
                </div>
                 
              </>
              <br />
              <hr />
              <br />

              {/* Program Registration */}
              <>
                <div className="theme-content-left-head">
                  <h3>Program registration</h3>
                </div>
                <div className="program-registration">
                <div className="input-box">
                    <label>
                    Student Type<i style={{ color: "red" }}>*</i>
                    </label>
                    <br></br>
                    <Select
                      placeholder="-- Student Type--"
                      style={{ width: "200px", display:"block" }}
                      onChange={(value) => {
                        set_student_type(value);
                      }}
                      value={student_type}
                    
                    >
                      {['New', 'Transferred', 'Returning', 'Alumni'].map((item) => {
                        return (
                          <>
                            <Select.Option value={item}>
                              {item}
                            </Select.Option>
                          </>
                        );
                      })}
                    </Select>
                    {errors?.student_type && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.student_type[0]}
                        </span>
                      </>
                    )}
                  </div>
                  {/* Matriculation Semester */}
                  <div className="input-box" style={{ width: "200px" }}>
                    <label>
                      Matriculation Semester<i style={{ color: "red" }}>*</i>
                    </label>
                    <br />
                    {/* <Input type='number'
                                    onChange={(e) => set_matriculation_semester(e.target.value)}
                                    placeholder="Enter Matriculation Semester"
                                    value={matriculation_semester}
                                    required /> */}

                    <Select
                      placeholder="--Select Semester--"
                      style={{ width: "100%" }}
                      onChange={(value) => {
                        set_matriculation_semester(value);
                      }}
                      showSearch
                      value={
                        matriculation_semester
                          ? parseInt(matriculation_semester)
                          : ""
                      }
                      filterOption={(inputValue, option) => {
                        if(!option.code.toLowerCase().indexOf(inputValue.toLowerCase())){
                            return !option.code.toLowerCase().indexOf(inputValue.toLowerCase())
                        }else{
                            return !option.search.toLowerCase().indexOf(inputValue.toLowerCase())
                        }
                       
                    }
                    }
                    options={table_list.map((item) => ({
                      value: item.id,
                      label: item.semester_title + " ( " + item.calendar_title + " )",
                      search:  item.semester_title,
                      code : item.calendar_title
                  }))}

                     />
                    
                    {errors?.matriculation_semester && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.matriculation_semester[0]}
                        </span>
                      </>
                    )}
                  </div>

                  {/* Matriculation Date */}
                  <div className="input-box" style={{ width: "200px" }}>
                    <label>
                      Matriculation Date<i style={{ color: "red" }}>*</i>
                    </label>
                    <br />

                    <DatePicker
                      value={matriculation_date && dayjs(matriculation_date)}
                      onChange={(date, dateString) =>
                        set_matriculation_date(dateString)
                      }
                      style={{ width: "100%" }}
                    />

                    {errors?.matriculation_date && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.matriculation_date[0]}
                        </span>
                      </>
                    )}
                  </div>

                  {/* Confirm Date */}
                  <div className="input-box" style={{ width: "200px" }}>
                    <label>
                      Confirm Date<i style={{ color: "red" }}>*</i>
                    </label>
                    <br />

                    <DatePicker
                      value={confer_date && dayjs(confer_date)}
                      onChange={(date, dateString) =>
                        set_confer_date(dateString)
                      }
                      style={{ width: "100%" }}
                    />

                    {/* <DatePicker
                                    value={dayjs(confer_date)}
                                    onChange={(date, dateString) => set_confer_date(date, dateString, 'start_date')}
                                    dateFormat='yy-mm-dd'
                                    style={{ width: "100%" }} /> */}
                    {errors?.confer_date && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.confer_date[0]}
                        </span>
                      </>
                    )}
                  </div>

                  {/* Program Status */}
                  <div className="input-box" style={{ width: "200px" }}>
                    <label>
                      Program Status<i style={{ color: "red" }}>*</i>
                    </label>
                    <br />
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Select Program Status"
                      onChange={(value) => {
                        set_programme_status(value);
                      }}
                      value={programme_status}
                      required
                    >
                      {/* <Select.Option value="N/A">N/A</Select.Option>
                                    <Select.Option value="Completed">Completed</Select.Option>
                                    <Select.Option value="Other">Other</Select.Option>
                                    <Select.Option value="active">Active</Select.Option>
                                    <Select.Option value="inactive">Inactive</Select.Option> */}

                      <Select.Option value="Active">Active</Select.Option>
                      <Select.Option value="Completed">Completed</Select.Option>
                      <Select.Option value="N/A">N/A</Select.Option>
                      <Select.Option value="Inactive">Inactive</Select.Option>
                      <Select.Option value="Leave of absent">
                        Leave of absent
                      </Select.Option>
                      <Select.Option value="Reduced Load">
                        Reduced Load
                      </Select.Option>
                      <Select.Option value="Transfer out">
                        Transfer out
                      </Select.Option>
                      <Select.Option value="withdraw">Withdraw</Select.Option>
                      <Select.Option value="Other">Other</Select.Option>
                    </Select>
                    {errors?.status_id && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.status_id[0]}
                        </span>
                      </>
                    )}
                  </div>

                  {/* Entering Classification */}
                  <div className="input-box" style={{ width: "200px" }}>
                    <label>
                      Entering Classification<i style={{ color: "red" }}>*</i>
                    </label>
                    <br />
                    <Select
                      onChange={(value) => {
                        set_entering_classification(value);
                      }}
                      value={entering_classification}
                      style={{ width: "100%" }}
                      placeholder="Select Entering Classifications"
                      required
                    >
                      <Select.Option value="undergraduate">
                        Undergraduate
                      </Select.Option>
                      <Select.Option value="graduate">Graduate</Select.Option>
                    </Select>
                    {errors?.entering_class && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.entering_class[0]}
                        </span>
                      </>
                    )}
                  </div>

                  {/* Entering Year */}
                  <div className="input-box" style={{ width: "200px" }}>
                    <label>
                      Entering Year<i style={{ color: "red" }}>*</i>
                    </label>
                    <br />

                    <DatePicker
                      value={entering_year && dayjs(entering_year)}
                      onChange={(date, dateString) =>
                        set_entering_year(dateString)
                      }
                      style={{ width: "100%" }}
                    />
                    {/* <DatePicker
                                    onChange={(date, dateString) => set_entering_year(dateString)}
                                    // value={entering_year}
                                    style={{ width: '100%' }}
                                    placeholder="Select Matriculation Date"
                                    required /> */}
                    {/* <Input onChange={(e) => set_entering_year(e.target.value)} type='number' placeholder="Enter Entering Year" required /> */}
                    {errors?.entering_year && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.entering_year[0]}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </>
              <br />
              <hr />
              <br />

              {/* Student status */}
              <>
                <div style={{ width: "100%" }}>
                  <div className="input-box" style={{ width: "200px" }}>
                    <label>
                      Student status<i style={{ color: "red" }}>*</i>
                    </label>
                    <br></br>
                    <Select
                      placeholder="--select student status--"
                      style={{ width: "100%" }}
                      onChange={(value) => {
                        set_student_status(value);
                      }}
                      value={student_status}
                    >
                        {student_status_check !== "Enrolled" &&  student_status_check && <Select.Option value={student_status_check}>
                        {student_status_check}
                        </Select.Option>} 
                      <Select.Option value="Enrolled">
                        Enrolled
                      </Select.Option>
                    </Select>
                    {errors?.student_status && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.student_status[0]}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </>
              <br />
              <br />
              <div className="input-box">
                {loader ? (
                  <>
                    <Button type="primary">
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: "12px",
                              color: "#fff",
                              marginRight: "5px",
                            }}
                          />
                        }
                      />{" "}
                      Enrol 
                    </Button>
                  </>
                ) : (
                  <>
                    <Button type="primary" onClick={ADD_API}>
                    Enrol
                    </Button>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AddEnrollment;
