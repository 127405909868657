import React, { useEffect, useState } from 'react';
import { Button, Checkbox, DatePicker, Input, Radio, Select, Spin, Table, notification } from 'antd';
import './style.css';
import { TableColumnsType, TableProps } from 'antd';
import { CloseOutlined, CloudUploadOutlined, EyeInvisibleOutlined, EyeTwoTone, LoadingOutlined, SmileOutlined } from '@ant-design/icons';
import { ADD_STUDENT, ADD_USER, EDIT_STUDENT, EMERGENCY_CONTACT, EMERGENCY_CONTACT_VIEW, ROLE_LIST, UPDATE_STUDENT } from '../../apis/apis';
import SisLoader from '../../widgets/loader';
import Item from 'antd/es/list/Item';
import { useNavigate, useParams } from 'react-router-dom';
import { CountryDropdown } from 'react-country-region-selector';
import dayjs from 'dayjs';

const EmergencyContact = () => {
    const { id } = useParams();
    const dateFormat = 'YYYY-MM-DD';
    const DATE_CLASS = new Date();
    const current_month = DATE_CLASS.getMonth() < 9 ? '0' + (DATE_CLASS.getMonth() + 1) : DATE_CLASS.getMonth();
    const today_date = DATE_CLASS.getFullYear() + '-' + current_month + '-' + DATE_CLASS.getDate();


    const navigate = useNavigate();
    const [loader, set_loader] = useState(false);
    const [errors, set_errors] = useState([]);



    // Form State
    
    const [first_name, set_first_name] = useState('');
    const [last_name, set_last_name] = useState('');
    const [phone, set_phone] = useState('');
    const [email, set_email] = useState('');
    const [address, set_address] = useState('');
    const [address_city, set_address_city] = useState('');
    const [address_state, set_address_state] = useState('');
    const [address_postal, set_address_postal] = useState('');
    const [address_country, set_address_country] = useState('');
    const [relationship, set_relationship] = useState('');

    const VIEW_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('student_id', atob(id));
        FORM_DATA.append('token', USER_DATA.token);
        const EDIT_API_RESPONSE = await EMERGENCY_CONTACT_VIEW(FORM_DATA);
        if (EDIT_API_RESPONSE?.data?.status && EDIT_API_RESPONSE?.data?.emergency_contacts) {
           
            set_first_name(EDIT_API_RESPONSE?.data?.emergency_contacts.first_name);
            set_last_name(EDIT_API_RESPONSE?.data?.emergency_contacts.last_name);
            set_phone(EDIT_API_RESPONSE?.data?.emergency_contacts.phone);
            set_email(EDIT_API_RESPONSE?.data?.emergency_contacts.email);
            set_relationship(EDIT_API_RESPONSE?.data?.emergency_contacts.relationship);
            set_address(EDIT_API_RESPONSE?.data?.emergency_contacts.address.address);
            set_address_city(EDIT_API_RESPONSE?.data?.emergency_contacts.address.address_city);
            set_address_state(EDIT_API_RESPONSE?.data?.emergency_contacts.address.address_state);
            set_address_postal(EDIT_API_RESPONSE?.data?.emergency_contacts.address.address_postal);
            set_address_country(EDIT_API_RESPONSE?.data?.emergency_contacts.address.address_country);
        }
     
    }
    useEffect(() => {VIEW_API()}, []);

    const ADD_API = async () => {
        set_loader(true)
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('student_id', atob(id));
        FORM_DATA.append('first_name', first_name);
        FORM_DATA.append('last_name', last_name);
        FORM_DATA.append('phone', phone);
        FORM_DATA.append('email', email);
        FORM_DATA.append('relationship', relationship);
        FORM_DATA.append('address', address);
        FORM_DATA.append('address_city', address_city);
        FORM_DATA.append('address_state', address_state);
        FORM_DATA.append('address_postal', address_postal);
        FORM_DATA.append('address_country', address_country);


        const API_RESPONSE = await EMERGENCY_CONTACT(FORM_DATA);
        if (API_RESPONSE.data.status) {
            notification.open({
                message: 'Success!!',
                description: 'Emergency Contact Successfully saved.',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
            navigate('/registration-documents/'+id);
        } else {
            set_errors(API_RESPONSE.data.errors);
            set_loader(false)
        }
    }
    return (
        <div>
            <div className='theme-content-head'>
                <div className='theme-content-left-head'>
                    <h3>Emergency Contact</h3>
                </div>
                <div className='theme-content-right-head'>

                </div>
            </div>
            <div className='common-form'>
                <div className='form-step-box'>
                    <div className={'form-step active'}>
                        <div className='form-step-line'></div>
                        <span>1</span>
                        <h3>Basic Information </h3>
                    </div>
                    <div className={'form-step running'}>
                        <div className='form-step-line'></div>
                        <span>2</span>
                        <h3>Emergency Contact</h3>
                    </div>
                    <div className={'form-step'}>
                        <span>3</span>
                        <h3>Document</h3>
                    </div>

                </div>
                {errors?.try && <><span style={{ color: "red" }}>{errors?.try[0]}</span></>}
                {errors?.catch && <><span style={{ color: "red" }}>{errors?.catch[0]}</span></>}
         
                <div className='row'>
                    <div className='col-4'>
                        <div className='input-box'>
                            <label>First Name<i style={{ color: "red" }}>*</i></label>
                            <Input value={first_name} onChange={(e) => set_first_name(e.target.value)} />
                            {errors?.first_name && <><span style={{ color: "red" }}>{errors?.first_name[0]}</span></>}
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className='input-box'>
                            <label>Last Name<i style={{ color: "red" }}>*</i></label>
                            <Input value={last_name} onChange={(e) => set_last_name(e.target.value)} />
                            {errors?.last_name && <><span style={{ color: "red" }}>{errors?.last_name[0]}</span></>}
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className='input-box'>
                            <label>Relationship<i style={{ color: "red" }}>*</i></label>
                            <Input value={relationship} onChange={(e) => set_relationship(e.target.value)} />
                            {errors?.relationship && <><span style={{ color: "red" }}>{errors?.relationship[0]}</span></>}
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <label className="cus-label">Address<i style={{ color: "red" }}>*</i></label>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='input-box'>
                                    <Input value={address} placeholder='Address' onChange={(e) => set_address(e.target.value)} />
                                    {errors?.address && <><span style={{ color: "red" }}>{errors?.address[0]}</span></>}
                                </div>
                            </div>
                            <div className='col-3'>
                                <div className='input-box'>
                                    <Input value={address_city} placeholder='City' onChange={(e) => set_address_city(e.target.value)} />
                                    {errors?.address_city && <><span style={{ color: "red" }}>{errors?.address_city[0]}</span></>}
                                </div>
                            </div>
                            <div className='col-3'>
                                <div className='input-box'>
                                    <Input value={address_state} placeholder='State' onChange={(e) => set_address_state(e.target.value)} />
                                    {errors?.address_state && <><span style={{ color: "red" }}>{errors?.address_state[0]}</span></>}
                                </div>
                            </div>
                            <div className='col-3'>
                                <div className='input-box'>
                                    <Input value={address_postal} placeholder='Zip' onChange={(e) => set_address_postal(e.target.value)} />
                                    {errors?.address_postal && <><span style={{ color: "red" }}>{errors?.address_postal[0]}</span></>}
                                </div>
                            </div>
                            <div className='col-3'>
                                <div className='input-box'>
                                    <CountryDropdown value={address_country} onChange={(value) => set_address_country(value)} className="ant-input css-dev-only-do-not-override-djtmh8 ant-input-outlined" />
                                    {errors?.address_country && <><span style={{ color: "red" }}>{errors?.address_country[0]}</span></>}
                                </div>
                            </div>
                        </div>
                    </div>
                  
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <div className='input-box'>
                            <label htmlFor="phone">Phone Number<i style={{ color: "red" }}>*</i></label>
                            <Input value={phone} maxLength={10} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} id='phone' onChange={(e) => set_phone(e.target.value)} />
                            {errors?.phone && <><span style={{ color: "red" }}>{errors?.phone[0]}</span></>}
                        </div>

                    </div>

                    <div className='col-6'>

                        <div className='input-box'>
                            <label htmlFor="email">Email<i style={{ color: "red" }}>*</i></label>
                            <Input value={email} id='email' onChange={(e) => set_email(e.target.value)} />
                            {errors?.email && <><span style={{ color: "red" }}>{errors?.email[0]}</span></>}
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='input-box'>
                            {loader ? <>
                                <Button type="primary"><Spin indicator={<LoadingOutlined style={{ fontSize: '12px', color: "#fff", marginRight: "5px" }} />} /> Save & Next</Button>
                            </> : <>
                                <Button type="primary" onClick={ADD_API}>Save & Next</Button>
                            </>}
                        </div>

                    </div>
                </div>

            </div>

        </div>
    );
};

export default EmergencyContact;