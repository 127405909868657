import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Input, Spin, notification } from 'antd';
import { EyeInvisibleOutlined, SmileOutlined, EyeTwoTone, LoadingOutlined } from '@ant-design/icons';
import { set_email_API, CHECK_PASSWORD_TOKEN, FORGOT_PASSWORD } from '../apis/apis';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import SisLoader from '../widgets/loader';

const ResetPassword = () => {
    const navigate = useNavigate();
    const { token, type } = useParams();
    const [page_loader, set_page_loader] = useState(false);
    const [email, set_email] = useState('');
    const [loader, set_loader] = useState(false);
    const [errors, set_errors] = useState([]);

   
 // Set Password API
 const forgot_password = async () => {
    set_loader(true);
    const FORM_DATA = new FormData();
    FORM_DATA.append('email', email);
    const PASSWORD_API_RESPONSE = await FORGOT_PASSWORD(FORM_DATA);
    // console.log(PASSWORD_API_RESPONSE)
    if (PASSWORD_API_RESPONSE?.data?.status) {
        // localStorage.setItem('sis_user_data', JSON.stringify(LOGIN_API_RESPONSE.data.data));
        // window.location.reload();
            notification.open({
                message: 'Success!!',
                description: 'Set password link successfully sended at email address.',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
        navigate('/')
            
    } else {
        set_errors(PASSWORD_API_RESPONSE?.data?.errors);
        set_loader(false);
    }

};
    return (
        <>
            {page_loader ? <><SisLoader /></> : <>

            <div className='password-box'>
                        <div className='password-box-head'>
                            <h4>Lincoln University - Student Information System</h4>
                            <h2>Forgot Password</h2>
                        </div>
                        <div className='login-box-body'>
                            {errors?.try && <><span style={{ color: "red" }}>{errors?.try[0]}</span></>}
                            {errors?.catch && <><span style={{ color: "red" }}>{errors?.catch[0]}</span></>}
                            {/* New Password */}
                            <div className='input-box'>
                                <label >Enter Email Address</label>
                                <Input
                                    placeholder="Enter Email Address"
                                    onChange={(e) => set_email(e.target.value)}
                                />
                          {errors?.email && <><span style={{ color: "red" }}>{errors?.email[0]}</span></>}
                            </div>

                            {/* <Checkbox checked={remember} onChange={toggleChecked}> Remember me</Checkbox> */}

                            <div className='input-box' style={{ textAlign: "center" }}>
                                {loader ? (
                                    <Button type="primary">
                                        <Spin indicator={<LoadingOutlined style={{ fontSize: '12px', color: "#fff", marginRight: "5px" }} />} />
                                        Forgot Password
                                    </Button>
                                ) : (
                                    <Button
                                        type="primary"
                                        
                                    onClick={forgot_password}
                                    >
                                        Forgot Password
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
            </>}

        </>


    );
};

export default ResetPassword;
