import React, { useEffect, useState } from 'react';
import { Button, Popconfirm, Table, notification, Input, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, SmileOutlined, StopOutlined, CloudDownloadOutlined, FilePdfOutlined } from '@ant-design/icons';
import SisLoader from '../../widgets/loader';
import { CLASSROOMS_LIST, DELETE_ROOM, STATUS_ROOM } from '../../apis/apis';
import { BACKEND_URL } from '../../config/config';

const RoomsList = () => {
    const navigate = useNavigate();
    const [loader, set_loader] = useState(true);
    const [errors, set_errors] = useState([]);
    const [popStatusDescription, set_popStatusDescription] = useState('Do you want to change Satus of Room');
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    // Form State
    const [table_list, set_table_list] = useState([]);

    // Search
    const handleSearch = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        const filtered = table_list.filter(item =>
            item.id.toString().toLowerCase().includes(query.toString().toLowerCase()) ||
            item.room_name.toString().toLowerCase().includes(query.toString().toLowerCase()) ||
            // item.room_type.toString().toLowerCase().includes(query.toString().toLowerCase()) ||
            item.building_name.toString().toLowerCase().includes(query.toString().toLowerCase()) ||
            item.floor.toString().toLowerCase().includes(query.toString().toLowerCase())
        );
        if(query === ''){
            setFilteredData(table_list);
        }else{
            setFilteredData(filtered);
        }
    };

    // Status
    const STATUS_API = async (id) => {
        set_loader(true)
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('id', id);
        const API_RESPONSE = await STATUS_ROOM(FORM_DATA);
        if (API_RESPONSE.data.status) {
            notification.open({
                message: 'Success!!',
                description: 'Room status Successfully changed.',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
            navigate('/rooms-list')
        } else {
            set_errors(API_RESPONSE.data.errors);
            set_loader(false)
        }
    }
    const DELETE_API = async (id) => {
        set_loader(true)
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('id', id);
        const API_RESPONSE = await DELETE_ROOM(FORM_DATA);
        if (API_RESPONSE.data.status) {
            notification.open({
                message: 'Success!!',
                description: 'Room Successfully deleted.',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
            navigate('/rooms-list')
        } else {
            set_errors(API_RESPONSE.data.errors);
            set_loader(false)
        }
    }

    // handle status Text
    const handleStatusText = (id, status) => {
        if (status === 1) {
            set_popStatusDescription('Do you want to make this room Inactive');
        } else { set_popStatusDescription('Do you want to make this room Active'); }
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'room',
            dataIndex: 'room_name',
            key: 'room_name',
            sorter: (a, b) => a.room_name.localeCompare(b.room_name),
        },
        {
            title: 'Room Type',
            dataIndex: 'room_type',
            key: 'room_type',
            render: (text, record) => {
                if (parseInt(record.room_type) === 1) {
                    return (
                        'Lecture Room'
                    );
                }
                if (parseInt(record.room_type) === 2) {
                    return (
                        'Studio'
                    );
                }
                if (parseInt(record.room_type) === 3) {
                    return (
                        'Lab'
                    );
                }
            }
        },
        {
            title: 'Building',
            dataIndex: 'building_name',
            key: 'building_name'
        },
        {
            title: 'Floor',
            dataIndex: 'floor',
            key: 'floor'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                if (record.status) {
                    return (
                        <span className="table-status-activate">Activate</span>
                    );
                } else {
                    return (
                        <span className="table-status-deactivate">Inactive</span>
                    );
                }
            }
        },
        {
            title: 'Action',
            key: 'Action',
            render: (text, record) => {
                return (
                    <>
                        <Popconfirm
                            title={record.status ? "Deactivate this room?" : "Activate this room?"}
                            description={<>
                            {record.status ? <>
                                <p><b>Are you sure you want to deactivate this room?</b> </p>
                                    <p>- You will not be able to schedule new classes for this room</p>
                                    <p>- Existing classes will remain scheduled </p>
                            </> : <>
                            <p><b>Are you sure you want to activate this room?</b> </p>
                                    <p>- You will be able to schedule new classes for this room</p>
                            </>}
                                  
                            </>}
                            onConfirm={() => {
                                STATUS_API(btoa(record.id))
                            }}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="primary" size="small" style={{ backgroundColor: "#888888", marginRight: "5px" }} onClick={() => handleStatusText(record.id, record.status)}>

                                <div class="tooltip"> <StopOutlined /> <span class="tooltiptext">Status Change</span> </div>

                            </Button>
                        </Popconfirm>

                        <Button type="primary" size="small" onClick={() => navigate('/edit-room/' + btoa(record.id))} style={{ marginRight: "5px" }}>

                            <div class="tooltip"> <EditOutlined /> <span class="tooltiptext">Edit n View</span> </div>

                        </Button>

                        <Popconfirm
                            title="Change Status"
                            description="Are you sure to delete this room?"
                            onConfirm={() => {
                                DELETE_API(btoa(record.id))
                            }}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="primary" size="small" danger>
                                <div class="tooltip"> <DeleteOutlined /> <span class="tooltiptext">Delete</span> </div>
                            </Button>
                        </Popconfirm>
                    </>
                )
            }

        },
    ];

    // DB list
    const LIST_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        const API_RESPONSE = await CLASSROOMS_LIST(FORM_DATA);
        if (API_RESPONSE.data.status) {
            set_table_list(API_RESPONSE.data.classrooms);
            setFilteredData(API_RESPONSE.data.classrooms);
            set_loader(false);
        } else {
            set_loader(false);
        }
    }


    useEffect(() => {
        LIST_API();
    }, [loader]);


    return (
        <div>
            <div className='theme-content-head'>
                <div className='theme-content-left-head'>
                    <h3>Rooms List</h3>
                </div>
                <div className='theme-content-right-head'>
                    <Button type='primary' onClick={() => navigate('/add-room')}>Add Room</Button>

                    {/* CSV Download */}
                    <Tooltip title="Download Users List CSV">
                        <Button type='primary' onClick={() => window.location = BACKEND_URL + '/download-rooms/' + JSON.parse(localStorage.getItem('sis_user_data')).token} ghost style={{ marginLeft: "5px" }}><CloudDownloadOutlined /></Button>
                    </Tooltip>

                    {/* PDF Download button  */}
                    <Tooltip title="Download Users List PDF">
                        <Button type='primary' onClick={() => window.location = BACKEND_URL + '/download-rooms-pdf/' + JSON.parse(localStorage.getItem('sis_user_data')).token} ghost style={{ marginLeft: "5px" }}><FilePdfOutlined /></Button>
                    </Tooltip>
                </div>
            </div>
            <div className='theme-content-head'>
                <div className='input-box'>
                    <Input
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearch}
                    />
                    {errors?.code && <span style={{ color: "red" }}>{errors?.code[0]}</span>}
                </div>
            </div>
            {loader ? <SisLoader /> :<Table columns={columns} dataSource={filteredData} />}

        </div>
    );
};

export default RoomsList;