import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Input,
  Select,
  Spin,
  Table,
  notification,
} from "antd";
import "./style.css";
import { TableColumnsType, TableProps } from "antd";
import {
  CloseOutlined,
  CloudUploadOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  LoadingOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import {
  ADD_USER,
  CATEGORIES_LIST,
  EDIT_CATEGORY,
  EDIT_USER,
  ROLE_LIST,
  SIS_STORAGE,
  UPDATE_CATEGORY,
  UPDATE_USER,
} from "../../apis/apis";
import SisLoader from "../../widgets/loader";
import Item from "antd/es/list/Item";
import { useNavigate, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";

const EditUser = () => {
  const { id } = useParams();
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const [loader, set_loader] = useState(false);
  const [errors, set_errors] = useState([]);
  const [cate_list, set_cate_list] = useState([]);
  const [page_loader, set_page_loader] = useState(true);
  // Form State
  const [title, set_title] = useState("");
  const [description, set_description] = useState("");
  const [parent, set_parent] = useState("");
  const [image, set_image] = useState("");
  const [url_image, set_url_image] = useState("");

  // DB list
  const EDIT_CATEGORY_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("id", id);
    FORM_DATA.append("token", USER_DATA.token);
    const EDIT_ROLE_API_RESPONSE = await EDIT_CATEGORY(FORM_DATA);
    if (EDIT_ROLE_API_RESPONSE?.data?.status) {
      set_title(EDIT_ROLE_API_RESPONSE?.data?.category?.title);
      set_description(EDIT_ROLE_API_RESPONSE?.data?.category?.description);
      set_parent(EDIT_ROLE_API_RESPONSE?.data?.category?.parent);
      set_image(EDIT_ROLE_API_RESPONSE?.data?.category?.image);
      set_page_loader(false);
    } else {
      set_errors(EDIT_ROLE_API_RESPONSE.data.errors);
    }
  };

  // ROLES list
  const CATEGORIES_LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    const LIST_API_RESPONSE = await CATEGORIES_LIST(FORM_DATA);
    if (LIST_API_RESPONSE?.data?.status) {
      set_cate_list(LIST_API_RESPONSE?.data?.categories);
      set_loader(false);
    } else {
      set_loader(false);
    }
  };

  useEffect(() => {
    CATEGORIES_LIST_API();
    EDIT_CATEGORY_API();
  }, []);

  const handleImageChange = (e) => {
    const files = e.target.files;
    if (
      (files[0].type === "image/jpeg" ||
        files[0].type === "image/jpg" ||
        files[0].type === "image/png") &&
      files[0].size < 2000001
    ) {
      set_url_image(files[0]);
    } else {
      notification.open({
        message: "Not Allowed",
        description:
          "Please check image File format must be .jpg or .png and Max. file size 2 Mb",
        icon: <SmileOutlined style={{ color: "red" }} />,
      });
    }
  };
  const handleRemoveImage = (e) => {
    set_url_image("");
  };

  const UPDATE_API = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", id);
    FORM_DATA.append("title", title);
    FORM_DATA.append("description", editorRef.current.getContent());
    FORM_DATA.append("image", url_image);
    FORM_DATA.append("parent", parent);
    const API_RESPONSE = await UPDATE_CATEGORY(FORM_DATA);
    if (API_RESPONSE.data.status) {
      notification.open({
        message: "Success!!",
        description: "Category Successfully added.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      navigate("/categories-list");
    } else {
      set_errors(API_RESPONSE.data.errors);
      set_loader(false);
    }
  };
  return (
    <div>
      <div className="theme-content-head">
        <div className="theme-content-left-head">
          <h3>Edit Category</h3>
        </div>
        <div className="theme-content-right-head"></div>
      </div>
      <div className="common-form">
        {page_loader ? (
          <SisLoader />
        ) : (
          <>
            {errors?.try && (
              <>
                <span style={{ color: "red" }}>{errors?.try[0]}</span>
              </>
            )}
            {errors?.catch && (
              <>
                <span style={{ color: "red" }}>{errors?.catch[0]}</span>
              </>
            )}
            <div className="row">
              <div className="col-12">
                <div className="input-box">
                  <label htmlFor="name">
                    Title<i style={{ color: "red" }}>*</i>
                  </label>
                  <Input
                    value={title}
                    placeholder="Title"
                    id="title"
                    onChange={(e) => set_title(e.target.value)}
                  />
                  {errors?.title && (
                    <>
                      <span style={{ color: "red" }}>{errors?.title[0]}</span>
                    </>
                  )}
                </div>

                {/* Description */}
                <div className="input-box">
                  <label htmlFor="name">Description</label>
                  {/* <label htmlFor="name">Description<i style={{ color: "red" }}>*</i></label> */}
                  <Editor
                    apiKey="i6i6aki8vkxt19vlfxol49qa6zukk6lry8hgtzka6agthn0x"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    initialValue={description}
                    init={{
                      height: 500,
                      menubar: false,
                      plugins:
                        "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount imagetools",
                      toolbar:
                        "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image code media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                  {errors?.title && (
                    <>
                      <span style={{ color: "red" }}>{errors?.title[0]}</span>
                    </>
                  )}
                </div>

                <div className="input-box">
                  <label htmlFor="Select-Role">
                    Select Parent Category
                  </label>
                  <br></br>
                  <Select
                    value={parent}
                    onChange={(value) => set_parent(value)}
                    style={{ width: "100%" }}
                    placeholder="Select Parent Category"
                  >
                    {cate_list?.length > 0 ? (
                      <>
                        <Select.Option value="">
                          Select Parent Category
                        </Select.Option>
                        {cate_list?.map((item) => (
                          <>
                            {atob(id) != item.id && !item.parent ? (
                              <Select.Option value={item.id}>
                                {item.title}
                              </Select.Option>
                            ) : (
                              ""
                            )}
                          </>
                        ))}
                      </>
                    ) : (
                      <>
                        <Select.Option value="">
                          No Parent Category
                        </Select.Option>
                      </>
                    )}
                  </Select>
                  {errors?.permission_role_id && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.permission_role_id[0]}
                      </span>
                    </>
                  )}
                </div>

                <div className="input-box" style={{ position: "relative" }}>
                  <label htmlFor="address">Category Photo</label>
                  <br></br>
                  <label className="upload-box">
                    {" "}
                    <input
                      type="file"
                      id="upload-images"
                      accept="image/*"
                      style={{ height: "0px", overflow: "hidden" }}
                      onChange={handleImageChange}
                    />{" "}
                    <CloudUploadOutlined /> Upload Image
                  </label>
                  {url_image ? (
                    <>
                      <div
                        className="image-box"
                        style={{
                          backgroundImage:
                            "url(" + URL.createObjectURL(url_image) + ")",
                        }}
                      ></div>
                      <button
                        className="image-remove"
                        onClick={() => handleRemoveImage()}
                      >
                        <CloseOutlined />
                      </button>
                    </>
                  ) : (
                    <>
                      {image && (
                        <>
                          <div
                            className="image-box"
                            style={{
                              backgroundImage:
                                "url(" +
                                SIS_STORAGE +
                                "/categories/" +
                                image +
                                ")",
                            }}
                          ></div>
                        </>
                      )}
                    </>
                  )}

                  {errors?.image && (
                    <>
                      <span style={{ color: "red" }}>{errors?.image[0]}</span>
                    </>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="input-box">
                  {loader ? (
                    <>
                      <Button type="primary">
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: "12px",
                                color: "#fff",
                                marginRight: "5px",
                              }}
                            />
                          }
                        />{" "}
                        Update
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button type="primary" onClick={UPDATE_API}>
                        Save
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EditUser;
