import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Input, Spin, notification } from 'antd';
import './set_password.css';
import { EyeInvisibleOutlined, SmileOutlined, EyeTwoTone, LoadingOutlined } from '@ant-design/icons';
import { SET_PASSWORD_API, CHECK_PASSWORD_TOKEN } from '../apis/apis';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import SisLoader from '../widgets/loader';

const SetPassword = () => {
    const navigate = useNavigate();
    const { token, type, user_type } = useParams();
    
    const [check_token, set_check_token] = useState(false);
    const [page_loader, set_page_loader] = useState(true);
    const [password, set_password] = useState('');
    const [password_confirmation, set_password_confirmation] = useState('');
    const [remember, set_remember] = useState(false);
    const [loader, set_loader] = useState(false);
    const [errors, set_errors] = useState([]);

    // Check tokn api
    const check_token_api = async () => {
        set_page_loader(true);
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', token)
        FORM_DATA.append('user_type', user_type)
        const CHECK_TOKEN_API_RESPONSE = await CHECK_PASSWORD_TOKEN(FORM_DATA);
        if (CHECK_TOKEN_API_RESPONSE?.data.status) {
            set_check_token(true)
            set_page_loader(false);

        } else {
            set_check_token(false)
            set_page_loader(false);
        }
    }
    useEffect(() => {
        check_token_api();
    }, [])

    const toggleChecked = () => {
        set_remember(!remember);
    };

    // Set Password API
    const add_password = async () => {
        set_loader(true);
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', token)
        FORM_DATA.append('password', password);
        FORM_DATA.append('user_type', user_type);
        FORM_DATA.append('password_confirmation', password_confirmation);
        const PASSWORD_API_RESPONSE = await SET_PASSWORD_API(FORM_DATA);
        // console.log(PASSWORD_API_RESPONSE)
        if (PASSWORD_API_RESPONSE?.data?.status) {
            // localStorage.setItem('sis_user_data', JSON.stringify(LOGIN_API_RESPONSE.data.data));
            // window.location.reload();
            notification.open({
                message: 'Success!!',
                description: 'Set Password Successfully',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
            navigate('/')

        } else {
            set_errors(PASSWORD_API_RESPONSE?.data?.errors);
            set_loader(false);
        }

    };

    return (
        <>
            {page_loader ? <><SisLoader /></> : <>

                {check_token ? <>
                    <div className='password-box'>
                        <div className='password-box-head'>
                            <h4>Lincoln University - Student Information System</h4>
                            <h2>Set Password </h2>
                        </div>
                        <div className='login-box-body'>
                            {errors?.try && <><span style={{ color: "red" }}>{errors?.try[0]}</span></>}
                            {errors?.catch && <><span style={{ color: "red" }}>{errors?.catch[0]}</span></>}
                            {/* New Password */}
                            <div className='input-box'>
                                <label >Set Password</label>
                                <Input.Password
                                    placeholder="Set Password"
                                    // autoComplete="new-password"
                                    // value={password}
                                    // pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                    onChange={(e) => set_password(e.target.value)}
                                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                />

                            </div>

                            {/* Confirm Password */}
                            <div className='input-box'>
                                <label >Confirm Password</label>
                                <Input.Password
                                    placeholder="Confirm Password"
                                    // autoComplete="new-password"
                                    // value={password_confirmation}
                                    // pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                    onChange={(e) => set_password_confirmation(e.target.value)}
                                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                />

                                {errors?.password && <span style={{ color: "red" }}>{errors?.password}</span>}
                            </div>

                            {/* <Checkbox checked={remember} onChange={toggleChecked}> Remember me</Checkbox> */}

                            <div className='input-box' style={{ textAlign: "center" }}>
                                {loader ? (
                                    <Button type="primary">
                                        <Spin indicator={<LoadingOutlined style={{ fontSize: '12px', color: "#fff", marginRight: "5px" }} />} />
                                        Set Password
                                    </Button>
                                ) : (
                                    <Button
                                        type="primary"
                                        onClick={add_password}
                                    // onClick={() =>{navigate('/')}}
                                    >
                                        Set Password
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </> : <>
                    <p style={{border:"1px solid #ccc", padding:"10px", maxWidth:"550px", margin:"100px auto", color:"red", fontWeight:"bold", fontSize:"20px", textAlign:"center" }}>Page Expired</p>
                </>}
            </>}

        </>


    );
};

export default SetPassword;
