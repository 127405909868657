import { Button, Input, Spin } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import StripePayment from "./Stripe Payment/stripePayment";
import { LoadingOutlined } from "@ant-design/icons";


const OnlineFeePayment = () => {
    const [coupon_code, set_coupon_code] = useState("");
    const [loader, set_loader] = useState(false);
    const [errors, set_errors] = useState([]);

    const navigate = useNavigate();

    return (
        <>
            <div className='row'>
                <div className='online-form-step-box'>
                    <div className={'online-form-step'} onClick={() => navigate('/')}>
                        <div className='online-form-step-line'></div>
                        <span>1</span>
                        <h3>Basic Information </h3>
                    </div>
                    <div className={'online-form-step'} onClick={() => navigate('/demographic-information')}>
                        <div className='online-form-step-line'></div>
                        <span>2</span>
                        <h3>Demographic Information</h3>
                    </div>
                    <div className={'online-form-step'} onClick={() => navigate('/finance-declaration')}>
                        <div className='online-form-step-line'></div>
                        <span>3</span>
                        <h3>Finance Declaration</h3>
                    </div>
                    <div className={'online-form-step'} onClick={() => navigate('/agreement')}>
                        <div className='online-form-step-line'></div>
                        <span>4</span>
                        <h3>Agreement</h3>
                    </div>
                    <div className={'online-form-step'} onClick={() => navigate('/formpreview')}>
                        <div className='online-form-step-line'></div>
                        <span>5</span>
                        <h3>Preview</h3>
                    </div>
                    <div className={'online-form-step running'}>
                        <span>6</span>
                        <h3>Payment</h3>
                    </div>
                </div>
            </div>
            <br />
            <hr />
            <br />
            <StripePayment />
            <br />
            <br />
            {/* button */}
            {/* <div className="row">
                <div className="col-1" style={{ marginRight: "15px" }}>
                    <div className="input-box">
                        {loader ? (
                            <>
                                <Button type="primary">
                                    <Spin
                                        indicator={
                                            <LoadingOutlined
                                                style={{
                                                    fontSize: "12px",
                                                    color: "#fff",
                                                    marginRight: "5px",
                                                }}
                                            />
                                        }
                                    />{" "}
                                    Previous
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button onClick={() => navigate("/formpreview")} type="primary">
                                    Previous
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default OnlineFeePayment;