import { useEffect, useState } from "react";
import AcademicsViewBillingCourseTable from "./academics_view_billing_course_table";
import { useNavigate } from "react-router-dom";
import { BILLING_PERIOD } from "../../../apis/apis";
import BillingPaymentPlan from "../Modals/billing_payment_plan";

const AcademicsViewBilling = ({ VIEW_BILLING_PERIOD_API_RESPONSE }) => {
  console.log("VIEW_BILLING_PERIOD_API_RESPONSE_check", VIEW_BILLING_PERIOD_API_RESPONSE)
  const navigate = useNavigate();
  const [billing_payment_plan_modal, set_billing_payment_plan_modal] =
    useState(false);
  const [loader, set_loader] = useState(true);
  const [errors, set_errors] = useState([]);
  const [tuition_fees, set_tuition_fees] = useState(
    VIEW_BILLING_PERIOD_API_RESPONSE?.data?.tuition_fees
  );
  const [billing_detail, set_billing_detail] = useState(
    VIEW_BILLING_PERIOD_API_RESPONSE?.data?.billing_period
  );
  const [course_fees, set_course_fees] = useState(
    VIEW_BILLING_PERIOD_API_RESPONSE?.data?.tuition_fees
  );
  const [semester_registration_fees, set_semester_registration_fees] = useState(
    []
  );
  const [semester_fees, set_semester_fees] = useState(1);
  const [late_registration_fees, set_late_registration_fees] = useState(
    VIEW_BILLING_PERIOD_API_RESPONSE?.data?.semester_registration_fees
      ?.late_registrations_fees
  );
  const [health_insurance_fees, set_health_insurance_fees] = useState(
    VIEW_BILLING_PERIOD_API_RESPONSE?.data?.semester_registration_fees
      ?.health_insurance
  );
  const [late_charges, set_late_charges] = useState(
    VIEW_BILLING_PERIOD_API_RESPONSE?.data?.semester_registration_fees
      ?.late_charges
  );
  const [material_fees, set_material_fees] = useState(
    VIEW_BILLING_PERIOD_API_RESPONSE?.data?.semester_registration_fees
      ?.material_fee
  );
  const [test_fees, set_test_fees] = useState(
    VIEW_BILLING_PERIOD_API_RESPONSE?.data?.semester_registration_fees?.test_fee
  );
  const [student_body_fees, set_student_body_fees] = useState(
    VIEW_BILLING_PERIOD_API_RESPONSE?.data?.semester_registration_fees
      ?.student_body
  );
  const [id_card_fees, set_id_card_fees] = useState(
    VIEW_BILLING_PERIOD_API_RESPONSE?.data?.semester_registration_fees
      ?.id_card_fee
  );
  const [note_fees, set_note_fees] = useState(
    VIEW_BILLING_PERIOD_API_RESPONSE?.data?.semester_registration_fees?.note_fee
  );
  const [publication_fees, set_publication_fees] = useState(
    VIEW_BILLING_PERIOD_API_RESPONSE?.data?.semester_registration_fees
      ?.publication_fee
  );
  const [ecg_lab_fees, set_ecg_lab_fees] = useState(
    VIEW_BILLING_PERIOD_API_RESPONSE?.data?.semester_registration_fees
      ?.ecg_lab_fee
  );
  const [tuition_recovery_fees, set_tuition_recovery_fees] = useState(
    VIEW_BILLING_PERIOD_API_RESPONSE?.data?.semester_registration_fees
      ?.tuition_recover_fee
  );
  const [total_credit_fee, set_total_credit_fee] = useState("");
  const [library_fees, set_library_fees] = useState(
    VIEW_BILLING_PERIOD_API_RESPONSE?.data?.semester_registration_fees
      ?.library_fee
  );
  const [lab_fees, set_lab_fees] = useState(
    VIEW_BILLING_PERIOD_API_RESPONSE?.data?.semester_registration_fees?.lab_fee
  );
  const [upfrontPaymentDate, setUpfrontPaymentDate] = useState("");
  const [upfrontPaymentAmount, setUpfrontPaymentAmount] = useState(
    VIEW_BILLING_PERIOD_API_RESPONSE?.data?.student_final_bill?.upfront_amount
  );
  const [payment_plan, set_payment_plan] = useState(
    VIEW_BILLING_PERIOD_API_RESPONSE?.data?.payment_plans
  );

  // Move state setting logic inside useEffect
  useEffect(() => {
    const creditFeesArray =
      VIEW_BILLING_PERIOD_API_RESPONSE?.data?.tuition_fees || [];
    const last = creditFeesArray[creditFeesArray?.length - 1];

    // Set total credit fee once after the component mounts
    if (last) {
      set_total_credit_fee(last?.total_tuition_fee);
    }

    if (
      VIEW_BILLING_PERIOD_API_RESPONSE?.data?.semester_registration_fees != null
    ) {
      set_semester_registration_fees(
        VIEW_BILLING_PERIOD_API_RESPONSE?.data?.semester_registration_fees
          ?.semester_registrations_fees
      );
    }
  }, [VIEW_BILLING_PERIOD_API_RESPONSE]);

  let sum_special_fees =
    (Number(health_insurance_fees) || 0) +
    (Number(late_registration_fees) || 0) +
    (Number(material_fees) || 0) +
    (Number(late_charges) || 0) +
    (Number(test_fees) || 0) +
    (Number(student_body_fees) || 0) +
    (Number(note_fees) || 0) +
    (Number(ecg_lab_fees) || 0) +
    (Number(tuition_recovery_fees) || 0) +
    (Number(publication_fees) || 0) +
    (Number(lab_fees) || 0) +
    (Number(library_fees) || 0);

  return (
    <>
      <div className="theme-content-head" style={{ marginBottom: "0px" }}>
        <div className="theme-content-left-head">
          <p>Billing Period</p>
          <h3 style={{ marginTop: "10px" }}>Fall 24</h3>
        </div>
      </div>
      <br />
      <hr />
      <br />
      {tuition_fees?.length > 0 && (
        <AcademicsViewBillingCourseTable tuition_fees={tuition_fees} />
      )}
      <br />
      <hr />
      <div className="Total_Due">
        <div className="billing_row">
          <span>Tuition Fees</span>
          <span>{total_credit_fee}</span>
        </div>
        <div className="billing_row">
          <span>Semester Registration Fees</span>
          <span>{semester_registration_fees}</span>
        </div>
        <div className="billing_row">
          <h4>Sub Total</h4>
          <h4>
            ${" "}
            {isNaN(
              Number(total_credit_fee) + Number(semester_registration_fees)
            )
              ? 0
              : Number(total_credit_fee) + Number(semester_registration_fees)}
          </h4>
        </div>

        <div style={{ color: "#8E8E8E" }} className="billing_row">
          {" "}
          Special Fees
        </div>

        {health_insurance_fees && !isNaN(health_insurance_fees) && (
          <>
            <div className="billing_row">
              <span>Health Insurance </span>
              <span>$ {health_insurance_fees} </span>
            </div>
          </>
        )}
        {late_registration_fees && !isNaN(late_registration_fees) && (
          <>
            <div className="billing_row">
              <span>Late Registration Fees </span>
              <span>$ {Number(late_registration_fees)} </span>
            </div>
          </>
        )}

        {material_fees && !isNaN(material_fees) && (
          <>
            <div className="billing_row">
              <span>Material Fees </span>
              <span>$ {Number(material_fees)} </span>
            </div>
          </>
        )}

        {late_charges && !isNaN(late_charges) && (
          <>
            <div className="billing_row">
              <span>Late Charges </span>
              <span>$ {Number(late_charges)} </span>
            </div>
          </>
        )}

        {test_fees && !isNaN(test_fees) && (
          <>
            <div className="billing_row">
              <span>Test Fees </span>
              <span>$ {Number(test_fees)} </span>
            </div>
          </>
        )}

        {student_body_fees && !isNaN(student_body_fees) && (
          <>
            <div className="billing_row">
              <span>Student Body Fees </span>
              <span>$ {Number(student_body_fees)} </span>
            </div>
          </>
        )}

        {note_fees && !isNaN(note_fees) && (
          <>
            <div className="billing_row">
              <span>Note Fees </span>
              <span>$ {Number(note_fees)} </span>
            </div>
          </>
        )}

        {tuition_recovery_fees && !isNaN(tuition_recovery_fees) && (
          <>
            <div className="billing_row">
              <span>Tuition Recovery Fees </span>
              <span>$ {parseInt(tuition_recovery_fees)} </span>
            </div>
          </>
        )}

        {publication_fees && !isNaN(publication_fees) && (
          <>
            <div className="billing_row">
              <span>Publication Fees </span>
              <span>$ {Number(publication_fees)} </span>
            </div>
          </>
        )}

        {lab_fees && !isNaN(lab_fees) && (
          <>
            <div className="billing_row">
              <span>Lab Fees </span>
              <span>$ {Number(lab_fees)} </span>
            </div>
          </>
        )}

        {library_fees && !isNaN(library_fees) && (
          <>
            <div className="billing_row">
              <span>Library Fees </span>
              <span>$ {Number(library_fees)} </span>
            </div>
          </>
        )}

        {id_card_fees && !isNaN(id_card_fees) && (
          <>
            <div className="billing_row">
              <span>Id Card Fees </span>
              <span>$ {Number(id_card_fees)} </span>
            </div>
          </>
        )}

        {ecg_lab_fees && !isNaN(ecg_lab_fees) && (
          <>
            <div className="billing_row">
              <span>ECG lab Fee </span>
              <span>$ {Number(ecg_lab_fees)} </span>
            </div>
          </>
        )}

        <div className="billing_row">
          <h4>Total of Special Fees</h4>
          <h4>$ {isNaN(sum_special_fees) ? 0 : sum_special_fees}</h4>
        </div>

        <div className="billing_row">
          <h4>Total billing amount</h4>
          <h4>
            ${" "}
            {isNaN(
              Number(total_credit_fee) +
                Number(semester_registration_fees) +
                sum_special_fees
            )
              ? 0
              : Number(total_credit_fee) +
                Number(semester_registration_fees) +
                sum_special_fees}
          </h4>
        </div>
        <hr />
        <div className="billing_row">
          <span>Upfront payment due today</span>
          <h4>$ {isNaN(upfrontPaymentAmount) ? 0 : upfrontPaymentAmount}</h4>
        </div>
        <hr />
        {payment_plan?.length > 0 && (
          <div className="Lu_sis_">
            <div className="billing_row">
              <span style={{ marginBottom: "5px" }}>
                Remaining amount to be paid in{" "}
                <span
                  style={{
                    color: "#1e90ff",
                    fontWeight: 500,
                    cursor: "pointer",
                  }}
                  onClick={() => set_billing_payment_plan_modal(true)}
                >
                  {payment_plan?.length} months payment plan
                </span>
              </span>
              <span>{}</span>
            </div>
          </div>
        )}
      </div>
      <BillingPaymentPlan
        billing_payment_plan_modal={billing_payment_plan_modal}
        set_billing_payment_plan_modal={set_billing_payment_plan_modal}
        payment_plan={payment_plan}
      />
    </>
  );
};

export default AcademicsViewBilling;
