import React, { useEffect, useState } from "react";
import { Tabs, notification } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Flex } from "antd";
import photo from "../images/random.jpg"
import {
  UploadOutlined,
  PaperClipOutlined,
  EditOutlined,
  SmileOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";

import {
  SIS_STORAGE,
  STUDENTS_DOCUMENTS_ADD,
  STUDENT_DOCUMENTS_LIST,
  STUDENT_PROFILE_API_LIST,
  sisDateFormat,
} from "../../apis/apis";
import SisLoader from "../../widgets/loader";
const { TabPane } = Tabs;
const StudentsProfileDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [personal_details, set_personal_details] = useState("");
  const [contact_details, set_contact_details] = useState("");
  const [student_documents, set_student_documents] = useState("");
  const [enrollments_documents_details, set_enrollments_documents_details] = useState("");
  const [loader, set_loader] = useState(true);
  const [mailing_address, set_mailing_address] = useState("");
  const [home_country_address, set_home_country_address] = useState("");
  const [page_loader, set_page_loader] = useState(true);
  const [staff_details, set_staff_details] = useState("");
  const [student_documents_list, set_student_documents_list] = useState([]);
  const location = useLocation();
  const profilePicUrl = location.state?.student?.profile_pic;
  const [selectedFiles, setSelectedFiles] = useState(
    Array(7)
      .fill(null)
      .map(() => ({ file: null, fileName: "" }))
  );
  const [enrollments_agreements, set_enrollments_agreements] = useState("");
  const [
    high_school_diploma_and_transcripts,
    set_high_school_diploma_and_transcripts,
  ] = useState("");
  const [
    transcripts_from_higher_educational,
    set_transcripts_from_higher_educational,
  ] = useState("");
  const [resume, set_resume] = useState("");
  const [
    photocopy_of_passport_or_equivalent_documents,
    set_photocopy_of_passport_or_equivalent_documents,
  ] = useState("");
  const [proof_of_english, set_proof_of_english] = useState("");
  const [essay_explaining_interest, set_essay_explaining_interest] =
    useState("");
  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    if (
      file &&
      (file.type === "application/pdf" || file.type.includes("image/"))
    ) {
      const newSelectedFiles = [...selectedFiles];
      newSelectedFiles[index] = { file, fileName: file.name };
      setSelectedFiles(newSelectedFiles);
    } else {
      alert("Please upload only PDF or image files.");
    }
  };
  const handleUploadClick = (index) => {
    document.getElementById(`fileInput${index}`).click();
  };
  const VIEW_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("student_id", parseInt(USER_DATA?.id));
    FORM_DATA.append("token", USER_DATA?.token);
    const EDIT_API_RESPONSE = await STUDENT_PROFILE_API_LIST(FORM_DATA);
    if (EDIT_API_RESPONSE?.data?.status) {
      set_personal_details(EDIT_API_RESPONSE?.data?.data?.personal_details)
      set_contact_details(EDIT_API_RESPONSE?.data?.data?.contact_details);
      set_mailing_address(JSON.parse(EDIT_API_RESPONSE?.data?.data?.contact_details?.mailing_address));
      set_home_country_address(JSON.parse(EDIT_API_RESPONSE?.data?.data?.contact_details?.home_country_address));
      set_student_documents(EDIT_API_RESPONSE?.data?.data?.student_documents);
      set_enrollments_documents_details(EDIT_API_RESPONSE?.data?.enrollments_documents_details);
      set_staff_details(EDIT_API_RESPONSE?.data?.data?.staff_details);
      set_loader(false);
      set_page_loader(false);
    }
  };

  useEffect(() => {
    VIEW_API();
  }, [loader]);


  const ADD_API = async (type, document_file) => {
    set_page_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("document_type", type);
    FORM_DATA.append("document_file", document_file);
    FORM_DATA.append("student_id", atob(id));
    FORM_DATA.append("user_type", "staff");
    const API_RESPONSE = await STUDENTS_DOCUMENTS_ADD(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      notification.open({
        message: "Success!!",
        description: "User document Successfully Uploaded....",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      set_loader(true);
    } else {
      notification.open({
        message: "Error!!",
        description: API_RESPONSE?.data?.errors.document_file[0],
        icon: <SmileOutlined style={{ color: "red" }} />,
      });
      set_loader(true);
    }
  };
  const handleImageChange = (e, type) => {
    const files = e.target.files;
    const fSize = Math.round(files[0].size / 1024);
    const maxSize = 3072; // Allowing file type
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)/;
    if (!allowedExtensions.exec(files[0]) && fSize < maxSize) {
      ADD_API(type, files[0]);
    } else {
      notification.open({
        message: "Not Allowed",
        description:
          "Please check file format must be .jpg or .png and Max. file size 3Mb",
        icon: <SmileOutlined style={{ color: "red" }} />,
      });
    }
  };
  const get_document_type_list = (type) => {
    var local_student_documents_list = student_documents_list;
    if (local_student_documents_list?.length > 0) {
      local_student_documents_list = local_student_documents_list.filter(
        (doc) => doc?.document_type === type
      );
      if (local_student_documents_list?.length > 0) {
        return (
          <>
            {local_student_documents_list?.map((item) => (
              <>
                <li
                  onClick={() =>
                    window.open(
                      SIS_STORAGE + "/enrollments/" + item?.document_file
                    )
                  } >{item?.document_file}
                </li>
              </>
            ))}
          </>
        );
      }
    }
  };
  const get_date_format = (s_date) => {
    const date = new Date(s_date);
    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };
  return (
    <>
      {loader ? (
        <SisLoader />
      ) : (
        <>

          <div>
            <div>
              <div className="personal-details first-letter-capitalize">My Profile</div>
            </div>
            <br></br>
            <div className="row">
              <div
                className="col-2"
                style={{ maxWidth: "100px", minWidth: "100px" }}  >
                <img
                  style={{
                    width: "80px",
                    height: "85px",
                    borderRadius: "14px",
                    marginleft: "-12px",
                  }}
                  src={SIS_STORAGE + "/enrollments/" + personal_details?.image}
                  alt="Profile Picture" />
              </div>
              <div className="col-8">
                <h3 style={{
                  marginBottom: "10px", width: "260px",
                  fontFamily: "roboto",
                  fontSize: "24px",
                  fontWeight: "401",
                }}>
                  {personal_details?.first_name}{" "}
                  {personal_details?.middle_name !== "null" &&
                    personal_details?.middle_name &&
                    personal_details?.middle_name}{" "}
                  {personal_details?.last_name}{" "}
                </h3>
                <div className="row">
                  <div className="col-3 first-letter-capitalize">
                    <p>Application number</p>
                    <h4 style={{ marginTop: "6px" }}>  {personal_details?.application_no}</h4>
                  </div>
                  <div className="col-3 first-letter-capitalize">
                    <p>Student ID</p>
                    <h4 style={{ marginTop: "6px" }}> {personal_details?.student_id}</h4>
                  </div>
                  <div className="col-3 first-letter-capitalize" style={{ textAlign: "left" }}>
                    <Button
                      type="primary"
                      icon={<EditOutlined />}
                      onClick={() => navigate("/editpopup")}     >
                      Edit
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <br></br>

            {/* personal details section  */}

            <div className="personal-details">Personal Details</div>
            <div className="row">
              <div className="col-3 first-letter-capitalize">
                <label>
                  Birth Date
                </label>
                <br></br>
              </div>

              <div className="col-3">
                <label style={{textTransform:"none"}}>
                  Country of Birth
                </label>
                <br></br>
              </div>

              <div className="col-3 first-letter-capitalize ">
                <label>
                  Birth place/city
                </label>
                <br></br>
              </div>

              <div className="col-3 first-letter-capitalize">
                <label>
                  Student Category
                </label>
                <br></br>
              </div>
            </div>


            <div className="row">
              <div className="col-3 student-profile-information">
                <label className="feb-1990"
                 style={{textTransform: "none" }}
                 >
                  {sisDateFormat(personal_details?.birth_date)}
                </label>
                <br></br>
              </div>

              <div className="col-3 student-profile-information">
                <label className="feb-1990"
                 style={{textTransform: "none" }}
                  >
                  {personal_details?.country_of_birth}
                </label>
                <br></br>
              </div>

              <div className="col-3 student-profile-information">
                <label className="feb-1990"
                 style={{textTransform: "none" }}
                 >
                  {personal_details?.city_of_birth}
                </label>
                <br></br>
              </div>

              <div className="col-3 student-profile-information">
                <label className="feb-1990"
                 style={{textTransform: "none" }}
                >
                  {personal_details?.student_category}
                </label>
                <br></br>
              </div>

            </div>
            <br></br>

            <div className="row">
              <div className="col-3 first-letter-capitalize">
                <label>
                  Gender
                </label>
                <br></br>
              </div>

              <div className="col-3 first-letter-capitalize">
                <label>
                  Citizenship
                </label>
                <br></br>
              </div>

              <div className="col-3 first-letter-capitalize">
                <label>
                  SSN
                </label>
                <br></br>
              </div>

            </div>

            <div className="row">
              <div className="col-3 student-profile-information first-letter-capitalize">
                <label className="feb-1990"
                 style={{textTransform: "none" }}
                >
                  {personal_details?.gender}
                </label>
                <br></br>
              </div>

              <div className="col-3 student-profile-information">
                <label className="feb-1990"
                 style={{textTransform: "none" }}
                >
                  {personal_details?.citizenship}
                </label>
                <br></br>
              </div>

              <div className="col-3 student-profile-information">
                <label className="feb-1990"
                 style={{textTransform: "none" }}
                >
                  {personal_details?.ssn !== 'null' && personal_details?.ssn && personal_details?.ssn}
                </label>
                <br></br>
              </div>

            </div>

            <hr
              style={{
                border: "1px solid rgba(238, 238, 238, 1)",
              }} />

            <div className="contact-details first-letter-capitalize">Contact Details</div>
            <div className="row">
              <div className="col-3 first-letter-capitalize">
                <label>
                  primary email
                </label>
                <br></br>
              </div>
              <div className="col-3 first-letter-capitalize">
                <label>
                  secondary email
                </label>
                <br></br>
              </div>
              <div className="col-2 first-letter-capitalize">
                <label>
                  Phone Number
                </label>
                <br></br>
              </div>
              <div className="col-2 first-letter-capitalize">
                <label>
                  Emergency Mobile
                </label>
                <br></br>
              </div>
            </div>



            <div className="row">
              <div className="col-3 student-profile-information">
                <label className="feb-1990"
                 style={{textTransform: "none" }}
                 >
                  {contact_details?.primary_email}
                </label>
                <br></br>
              </div>
              <div className="col-3 student-profile-information">
                <label className="feb-1990" 
                 style={{textTransform: "none" }} 
                >
                  {contact_details?.secondary_email}
                </label>
                <br></br>
              </div>
              <div className="col-2 student-profile-information">
                <label className="feb-1990" 
                 style={{textTransform: "none" }}>
                  {contact_details?.phone}
                </label>
                <br></br>
              </div>
              <div className="col-2 student-profile-information">
                <label className="feb-1990"
                 style={{textTransform: "none" }}>
                  {contact_details?.emergency_contacts?.phone}
                </label>
                <br></br>
              </div>
            </div>

            <br />
            <div className="frame-427318302">
              <div className="current-mailing-address first-letter-capitalize">
                Current Mailing Address
              </div>
              <span className="bristol-avenue-broad-street-4-th-lane-new-york-usa-99090"
              style={{textTransform: "none" }}
              >
                {mailing_address?.mailing_address1},{" "}
                {(mailing_address?.mailing_address2) === "null" ? "" : mailing_address?.mailing_address2},{" "}
                {mailing_address?.mailing_address_city},{" "}
                {mailing_address?.mailing_address_country},{" "}
                {mailing_address?.mailing_address_postal}.
              </span>
            </div>
            <div className="frame-427318303">
              <div className="home-address first-letter-capitalize">Home Address</div>
              <span className="b-22-krishna-colony-manohar-nagar-jaipur-india-302025"
               style={{textTransform: "none" }}
              >
                {home_country_address?.home_country_address1},{" "}
                {(home_country_address?.home_country_address2) === "null" ? "" : home_country_address?.home_country_address2 },{" "}
                {home_country_address?.home_country_address_city},{" "}
                {home_country_address?.home_country_address_country},{" "}
                {home_country_address?.home_country_address_postal}.
              </span>
            </div>
            <hr style={{ border: "1px solid rgba(238, 238, 238, 1)" }} />
            <h1 className="first-letter-capitalize"
              style={{
                fontWeight: "401",
                fontSize: "18px",
                color: "var(--neutral-500, #8E8E8E)",
              }} >
              Documents
            </h1>
            {page_loader ? (
              <SisLoader />
            ) : (

              <>
                <div className="row" style={{ marginTop: "15px" }}>
                  <div className="col-3 " style={{ position: "relative" }}>
                    <h4 className="first-letter-capitalize" style={{ color: "#8E8E8E" }}>Enrollment Agreement</h4>
                    <ul className="document-list">
                      <li
                        title={student_documents?.enrollments_agreements}
                        onClick={() =>
                          window.open(
                            SIS_STORAGE +
                            "/enrollments/" +
                            student_documents?.enrollments_agreements
                          )} >
                        {student_documents?.enrollments_agreements}
                      </li>
                      {get_document_type_list("enrollments_agreements")}
                    </ul>
                  </div>
                  <div className="col-3 " style={{ position: "relative" }}>
                    <h4 className="first-letter-capitalize" style={{ color: "#8E8E8E", textTransform: "none" }}>High School Diploma and Transcripts</h4>
                    <ul className="document-list">
                      <li
                        title={student_documents?.high_school_diploma_and_transcripts}
                        onClick={() =>
                          window.open(
                            SIS_STORAGE +
                            "/enrollments/" +
                            student_documents?.high_school_diploma_and_transcripts
                          )
                        }  >
                        {student_documents?.high_school_diploma_and_transcripts}
                      </li>
                      {get_document_type_list(
                        "high_school_diploma_and_transcripts"
                      )}
                    </ul>
                  </div>
                  <div className="col-3  " style={{ position: "relative" }}>
                    <h4 className="first-letter-capitalize" style={{ color: "#8E8E8E", textTransform: "none" }}>Transcription from Higher Education</h4>
                    <ul className="document-list">
                      <li
                        title={student_documents?.transcripts_from_higher_educational}
                        onClick={() =>
                          window.open(
                            SIS_STORAGE +
                            "/enrollments/" +
                            student_documents?.transcripts_from_higher_educational
                          )
                        } >
                        {student_documents?.transcripts_from_higher_educational}
                      </li>
                      {get_document_type_list(
                        "transcripts_from_higher_educational"
                      )}
                    </ul>
                  </div>
                  <div className="col-3 " style={{ position: "relative", paddingLeft: "33px" }}>
                    <h4 className="first-letter-capitalize" style={{ color: "#8E8E8E" }}>Resume</h4>
                    <ul className="document-list">
                      <li
                        title={student_documents?.resume}
                        onClick={() =>
                          window.open(
                            SIS_STORAGE +
                            "/enrollments/" +
                            student_documents?.resume
                          )
                        }  >
                        {student_documents?.resume}
                      </li>
                      {get_document_type_list("resume")}
                    </ul>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "15px" }}>
                  <div className="col-3" style={{ position: "relative" }}>
                    <h4 className="first-letter-capitalize" style={{ color: "#8E8E8E" }}>Photocopy of Passport or Equivalent Documents</h4>
                    <ul className="document-list">
                      <li
                        title={
                          student_documents?.photocopy_of_passport_or_equivalent_documents
                        }
                        onClick={() =>
                          window.open(
                            SIS_STORAGE +
                            "/enrollments/" +
                            student_documents?.photocopy_of_passport_or_equivalent_documents
                          )
                        }
                      >
                        {
                          student_documents?.photocopy_of_passport_or_equivalent_documents
                        }
                      </li>
                      {get_document_type_list(
                        "photocopy_of_passport_or_equivalent_documents"
                      )}
                    </ul>
                  </div>
                  <div className="col-3" style={{ position: "relative" }}>
                    <h4 className="first-letter-capitalize" style={{ color: "#8E8E8E", textTransform: "none" }}>Proof of English</h4>
                    <ul className="document-list">
                      <li
                        title={student_documents?.proof_of_english}
                        onClick={() =>
                          window.open(
                            SIS_STORAGE +
                            "/enrollments/" +
                            student_documents?.proof_of_english
                          )
                        }
                      >
                        {student_documents?.proof_of_english}
                      </li>
                      {get_document_type_list("proof_of_english")}
                    </ul>
                  </div>
                  <div className="col-3 " style={{ position: "relative" }}>
                    <h4 className="first-letter-capitalize" style={{ color: "#8E8E8E", textTransform: "none" }}>Essay Explaining Interest</h4>
                    <ul className="document-list">
                      <li
                        title={student_documents?.essay_explaining_interest}
                        onClick={() =>
                          window.open(
                            SIS_STORAGE +
                            "/enrollments/" +
                            student_documents?.essay_explaining_interest
                          )
                        }
                      >
                        {student_documents?.essay_explaining_interest}
                      </li>
                      {get_document_type_list("essay_explaining_interest")}
                    </ul>
                  </div>
                </div>
              </>
            )}
            <br />
            {staff_details && (
              <>
                <div style={{ color: "#8E8E8E" }}>
                  Last updated by:{" "}
                  <b style={{ color: "#C7797A" }}>{staff_details?.name}{" "}
                   </b>{" "}
                  {get_date_format(student_documents.updated_at)}
                </div>
                <br />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default StudentsProfileDetails;
