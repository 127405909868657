import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';

const SisLoader = () => {
    return (
        <div style={{ position: "relative", display: "block" , height:"100px"}}>
            <div style={{ position: "absolute", left: "50%", top: "50%"}}>
            <LoadingOutlined style={{ fontSize:"26px" }} />
            {/* <Spin indicator={<LoadingOutlined style={{ fontSize: '12px', color: "#fff", marginRight: "5px" }} />} /> */}
            </div>
        </div>
    );
};

export default SisLoader;