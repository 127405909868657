import { Checkbox, Input, Popover, notification } from "antd";
import Search from "antd/es/transfer/search";
import React, { useEffect, useState } from "react";
import {
  COURSES_LIST,
  SEARCH_COURSE,
  SEMESTERS_COURSES_UPDATE,
  SEMESTERS_COURSES_DELETE,
} from "../../../apis/apis";
import { useNavigate } from "react-router-dom";
import {
  SearchOutlined,
  UserOutlined,
  LoadingOutlined,
  SmileOutlined,
  QuestionCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

const SearchCourseCheckbox = (props) => {
  const { calendar_id, select_courses, sem_id, item } = props;
  const navigate = useNavigate();
  const [loader, set_loader] = useState(false);
  const [errors, set_errors] = useState([]);
  const [table_list, set_table_list] = useState([]);
  const [table_list_search, set_table_list_search] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const UPDATE_API = async (course_id, id) => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("calendar_id", atob(calendar_id));
    FORM_DATA.append("semester_id", atob(sem_id));
    FORM_DATA.append("course_id", course_id);
    FORM_DATA.append("id", id);
    const API_RESPONSE = await SEMESTERS_COURSES_UPDATE(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      notification.open({
        message: "Success!!",
        description: "Course section added successfully!",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      navigate("/semester-courses/" + calendar_id + "/" + sem_id);
      set_loader(false);
    } else {
      set_errors(API_RESPONSE.data.errors);
    }
  };
  const DELETE_API = async (schedule_id) => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);

    FORM_DATA.append("id", schedule_id);
    const API_RESPONSE = await SEMESTERS_COURSES_DELETE(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      notification.open({
        message: "Success!!",
        description: "Course section removed successfully!",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      navigate("/semester-courses/" + calendar_id + "/" + sem_id);
      set_loader(false);
    } else {
      set_errors(API_RESPONSE.data.errors);
    }
  };
  const set_sem_course = (e, item, selected_course_row) => {
    set_loader(true);
    if (e.target.checked) {
      UPDATE_API(item.id, 0);
    } else {
      var filter = selected_course_row.filter(
        (fitem) => fitem.course_id === item.id
      );
      DELETE_API(filter[0].schedule_id);
    }
  };

  return (
    <>
      {loader ? (
        <LoadingOutlined />
      ) : (
        <>
          <label>
            {" "}
            <Checkbox
              onChange={(e) => set_sem_course(e, item, select_courses)}
              checked={
                select_courses
                  ?.map((itemA) => itemA.course_id)
                  .indexOf(item.id) > -1
                  ? true
                  : false
              }
            />{" "}
            {item.title}
            {item.perquisite_courses && (
              <>
              <Popover content={<>
                Prerequisites:
                {item.perquisite_courses.map((p_item) => (
                  <><span style={{ fontWeight:"bold", margin:"2px" }}>{p_item.code}, </span></>
                ))}
                  and at least two concentration course
              </>} title={<><ExclamationCircleOutlined style={{ color:"blue" }} /> Prerequisite requirement</>} trigger="hover">
              <i title="Perquisite course">P</i>

              </Popover>
                
              </>
            )}
          </label>
        </>
      )}
    </>
  );
};

export default SearchCourseCheckbox;
