import { useEffect, useState } from "react";
import { SIS_STORAGE, STUDENT_PROFILE_API_LIST } from "../../../apis/apis"
import user_icon from '../../../images/user.png';

const LayoutSelfEnrolledStudentName = () => {

  const [student_name, set_Student_Name] = useState('');
  const [api_image, set_Api_Image] = useState('');

  const STUDENTINFORMATION = async () => {

    try {
      const STUDENT_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
      const FORM_DATA = new FormData();
      FORM_DATA.append("student_id", STUDENT_DATA.id);
      FORM_DATA.append("token", STUDENT_DATA.token);
      const VIEW_STUDENT_NAME_API_RESPONSE = await STUDENT_PROFILE_API_LIST(FORM_DATA);
      if (VIEW_STUDENT_NAME_API_RESPONSE?.data?.status) {
        localStorage.setItem("personal_information_status",VIEW_STUDENT_NAME_API_RESPONSE?.data?.data?.application_status?.personal_information_status);
        localStorage.setItem("demographic_information_status",VIEW_STUDENT_NAME_API_RESPONSE?.data?.data?.application_status?.demographic_information_status);
        localStorage.setItem("finance_declaration_information_status",VIEW_STUDENT_NAME_API_RESPONSE?.data?.data?.application_status?.finance_declaration_information_status);
        localStorage.setItem("agreement_information_status",VIEW_STUDENT_NAME_API_RESPONSE?.data?.data?.application_status?.agreement_information_status);
        localStorage.setItem("payment_status",((VIEW_STUDENT_NAME_API_RESPONSE?.data?.data?.application_status?.payment_status) === false) || ((VIEW_STUDENT_NAME_API_RESPONSE?.data?.data?.application_status?.payment_status) === 0)  ? 0 : 1);
        const userData = VIEW_STUDENT_NAME_API_RESPONSE?.data?.data?.personal_details;

         // Update the student data with new values
      STUDENT_DATA.first_name = userData?.first_name && userData.first_name !== "null" ? userData.first_name : "";
      STUDENT_DATA.middle_name = userData?.middle_name && userData.middle_name !== "null" ? userData.middle_name : "";
      STUDENT_DATA.last_name = userData?.last_name && userData.last_name !== "null" ? userData.last_name : "";

      // Save the updated student data back to local storage
      localStorage.setItem("sis_user_data", JSON.stringify(STUDENT_DATA));
      
        const FullName = [userData?.first_name, userData?.middle_name, userData?.last_name]
            .filter(name => name && name !== "null")
            .join(" ");


        set_Student_Name(FullName);
        set_Api_Image(userData?.image);
      }

    }
    catch (error) {
      console.error("Error fetching Faculty data:", error);
    }

  }

  useEffect(() => {

    STUDENTINFORMATION();

  }, []);

  return (
    <>
      <div className="theme-user">
        <div className="theme-user-image">
          {api_image ? (
            <div className="theme-user-image">
              <img
                src={SIS_STORAGE + "/enrollments/" + api_image}
                style={{ width: "50px", height: "50px", borderRadius: "50%", }}
              ></img>
            </div>
          ) :
            <div className="theme-user-image">
              <img
                src={user_icon}
                style={{ width: "50px", height: "50px", borderRadius: "50%", }}
              ></img>
            </div>
          }
        </div>
        <div className="theme-user-detail">
          <p style={{ textTransform: "capitalize", padding: "0 5px 0 10px", wordBreak: "break-word", marginTop: "13px" }}>
            <span>{student_name}</span>
            {/* <span>Student</span> */}
          </p>
        </div>
      </div>
    </>
  )
}

export default LayoutSelfEnrolledStudentName;