import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { COURSES_LIST } from '../../../apis/apis';
import SisLoader from '../../../widgets/loader';

const CourseContainer = (props) => {
    const { set_courses, courses } = props;
    const [table_list, set_table_list] = useState([]);
    const [table_list_2, set_table_list_2] = useState([]);
    const [loader, set_loader] = useState(true);
    const options = [];
    const LIST_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        const API_RESPONSE = await COURSES_LIST(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            var filteredData = API_RESPONSE?.data?.courses.filter(item => item.soft_delete === 0 && item.status === 1);
            set_table_list(API_RESPONSE?.data?.courses);
            set_loader(false)
            set_table_list_2(filteredData);
        } else {
            set_loader(false);
        }
    }

    
    useEffect(() => {
        LIST_API();
    }, []);

   
    // const search_item = (item) => {
    //     if(courses.length > 0){
    //         const query = item;
    //         var table_data = table_list;
    //         const filtered = table_data.filter(item =>
    //             item.title.toLowerCase().includes(query.toLowerCase()) || item.code == query
               
    //         );
    //         if(query === ''){
    //             set_table_list(table_list);
    //         }else{
    //             setFilteredData(filtered);
    //         }
    //     }else{
    //         const query = item;
    //         var table_data = table_list;
    //         const filtered = table_data.filter(item =>
    //             item.title.toLowerCase().includes(query.toLowerCase()) || item.code == query
               
    //         );
    //         if(query === ''){
    //             set_table_list(table_list);
    //         }else{
    //             setFilteredData(filtered);
    //         }
    //     }
       
    // }
    
    return (
        <>
            {loader ? <SisLoader /> : <>
                <div className='input-box'>
                    <label htmlFor="Select-Role">Select Courses<i style={{ color: "red" }}>*</i></label><br></br>
                    {courses.length > 0 ? <>
                        <Select
                        mode="multiple"
                        placeholder="Select Courses"
                        defaultValue={courses}
                        style={{ width: '100%' }}
                        allowClear
                        filterOption={(inputValue, option) => {
                            if(!option.code.toLowerCase().indexOf(inputValue.toLowerCase())){
                                return !option.code.toLowerCase().indexOf(inputValue.toLowerCase())
                            }else{
                                return !option.search.toLowerCase().indexOf(inputValue.toLowerCase())
                            }
                           
                        }
                        }
                        onChange={(value) => set_courses(value)}
                        options={table_list.map((item) => ({
                            value: item.id,
                            label: item.select_box_label,
                            search:  item.select_box_label,
                            code : item.code
                        }))}
                    />
                    </> : <>
                    <Select
                        mode="multiple"
                        placeholder="Select Courses"
                        defaultValue={courses}
                        style={{ width: '100%' }}
                        allowClear
                        filterOption={(inputValue, option) => {
                            if(!option.code.toLowerCase().indexOf(inputValue.toLowerCase())){
                                return !option.code.toLowerCase().indexOf(inputValue.toLowerCase())
                            }else{
                                return !option.search.toLowerCase().indexOf(inputValue.toLowerCase())
                            }
                           
                        }
                        }
                        onChange={(value) => set_courses(value)}
                        options={table_list_2.map((item) => ({
                            value: item.id,
                            label: item.select_box_label,
                            search:  item.select_box_label,
                            code : item.code
                        }))}
                    >
                        {/* {table_list.map((item) => (
                            <>
                            {item.status === 1 && <Select.Option value={item.id} >{item.title + "(" + item.code + ")"}</Select.Option>}
                            </>
                        ))} */}
                    </Select>
                    
                    </>}
                   
                </div>
            </>}
        </>
    );
};

export default CourseContainer;