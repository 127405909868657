import React, { useEffect, useState } from "react";
import "./style.css";
import { Button, DatePicker, Input, Select, notification } from "antd";
import { COURSES_LIST, TRANSFERRED_COURSE_ADD, TRANSFERRED_COURSE_DELETE, TRANSFERRED_COURSE_LIST, TRANSFERRED_COURSE_UPDATE } from "../../../apis/apis";
import { DeleteOutlined, PlusOutlined, SmileOutlined } from "@ant-design/icons";
import TransferredCourseAdd from "./TransferredCourseAdd";
import SingleTransferredCourse from "./SingleTransferredCourse";
export default function TransferredCourse(props) {
  const {
    acad_calender_id,
    student_id,
    semester_id,
    sem_reg_id,
    set_sem_reg_id,
    set_transferred_Course,
    set_main_page_loader
  } = props;
  const [courses, set_courses] = useState([]);
  const [transferred_course_list, set_transferred_course_list] = useState([]);
  const [table_list_2, set_table_list_2] = useState([]);
  const [semester_registration_id, set_semester_registration_id] = useState(props.sem_reg_id);
  const [loader, set_loader] = useState(false);
  const [loader_change, set_loader_change] = useState(false);
  const [add_count, set_add_count] = useState(0);
  const COURSES_LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
    const FORM_DATA = new FormData();
    FORM_DATA.append('token', USER_DATA.token);
    const API_RESPONSE = await COURSES_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
        var filteredData = API_RESPONSE?.data?.courses.filter(item => item.soft_delete === 0);
        set_table_list_2(filteredData);
        set_loader(false);
    } else {
        set_loader(false);
    }
}
  const LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("acad_calender_id", acad_calender_id);
    FORM_DATA.append("semester_id", semester_id);
    FORM_DATA.append("student_id", student_id);
    FORM_DATA.append("semester_registration_id", semester_registration_id);
    const API_RESPONSE = await TRANSFERRED_COURSE_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_transferred_course_list(API_RESPONSE?.data?.TransferredCourse);
    } else {
      set_loader(false);
    }
  };
  const ADD_API = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("acad_calender_id", acad_calender_id);
    FORM_DATA.append("semester_id", semester_id);
    FORM_DATA.append("student_id", student_id);
    FORM_DATA.append("semester_registration_id", semester_registration_id);
    const API_RESPONSE = await TRANSFERRED_COURSE_ADD(FORM_DATA);
    if (API_RESPONSE.data.status) {
      notification.open({
        message: "Success!!",
        description: "Transfer course added successfully!",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      set_semester_registration_id(API_RESPONSE.data.id);
      set_transferred_Course(true);
      set_loader(false);
    } else {
      set_loader(false);
    }
  };
  const UPDATE_API = async (id) => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", id);
    const API_RESPONSE = await TRANSFERRED_COURSE_DELETE(FORM_DATA);
    if (API_RESPONSE.data.status) {
      notification.open({
        message: "Success!!",
        description: "Transfer course updated successfully!",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      set_loader(false);
    } else {
      set_loader(false);
    }
  };
  useEffect(() => {
    if(semester_registration_id){
      LIST_API();
    }
    COURSES_LIST_API()
  }, [loader]);
  return (
    <>
      {transferred_course_list.length > 0 && (
        <>
          {transferred_course_list?.map((transferred_course) => (
            <>
              <SingleTransferredCourse set_main_page_loader={set_main_page_loader} transferred_course={transferred_course} acad_calender_id={acad_calender_id} semester_id={semester_id} student_id={student_id} set_transferred_Course={set_transferred_Course} set_sem_reg_id={set_sem_reg_id} sem_reg_id={sem_reg_id} set_loader={set_loader} />
            </>
          ))}
        </>
      )}
      {loader ? (
        <>
          <Button type="primary" size="small" disabled>
            <PlusOutlined /> Add More
          </Button>
        </>
      ) : (
        <>
          <Button type="primary" size="small" onClick={ADD_API}>
            <PlusOutlined /> Add More
          </Button>
        </>
      )}
      <br /><br />
    </>
  );
}
