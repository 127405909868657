/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import {
  DownOutlined,
  RightOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Input,
  Collapse,
  Form,
  InputNumber,
  DatePicker,
  Space,
  Option,
  Popconfirm,
  Select,
  Spin,
  Table,
  notification,
} from "antd";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  SEMESTERS_LIST,
  SEMESTER_REGISTRATION_VIEW,
  STUDENTS_LIST,
} from "../../apis/apis";
import SisLoader from "../../widgets/loader";
const SemesterRegistrationView = () => {
  const { id, sem_id } = useParams();

  const history = useLocation();
  const navigate = useNavigate();
  const [loader, set_loader] = useState(true);
  const [errors, set_errors] = useState([]);
  const [sem_courses_list, set_sem_courses_list] = useState([]);
  const [table_list, set_table_list] = useState([]);
  const [page_loader, set_page_loader] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
    // Search
    const handleSearch = (e) => {
      const query = e.target.value;
      setSearchQuery(query);
      const filtered = table_list.filter(
        (item) =>
          item.first_name.toLowerCase().includes(query.toLowerCase()) ||
          item.phone
            .toString()
            .toLowerCase()
            .includes(query.toString().toLowerCase()) ||
          item.primary_email
            .toString()
            .toLowerCase()
            .includes(query.toString().toLowerCase()) ||
          item.student_status
            .toString()
            .toLowerCase()
            .includes(query.toString().toLowerCase()) ||
          item.application_no
            .toString()
            .toLowerCase()
            .includes(query.toString().toLowerCase())
      );
      if (query === "") {
        setFilteredData(table_list);
      } else {
        setFilteredData(filtered);
      }
    };
  
  const LIST_API = async () => {
    set_page_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("calendar_id", id);
    const API_RESPONSE = await SEMESTERS_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_sem_courses_list(API_RESPONSE.data.semesters);
      
      var first_semester = btoa(API_RESPONSE.data.semesters[0].id);
      if (sem_id) {
        first_semester = sem_id;
      }
      set_loader(false);
      set_page_loader(false);
    } else {
      set_loader(false);
    }
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      render: (text, record) => {
        if (record.semester_register_status) {
          return (
            <>
              {record.first_name} 
                {record.middle_name === 'null' ? ' ' : record.middle_name} 
                {record.last_name}
            </>
          );
        } else {
          return (
            <>
              {record.first_name} 
                {record.middle_name === 'null' ? ' ' : record.middle_name} 
                {record.last_name}
              <span
                style={{
                  backgroundColor: "red",
                  fontSize: "10px",
                  position: "relative",
                  color: "#fff",
                  padding: "2px 5px",
                  top: "-6px",
                  left: "3px",
                  borderRadius: "5px",
                }}
              >
                New
              </span>
            </>
          );
        }
      },
    },
    {
      title: "Application No",
      dataIndex: "application_no",
      key: "application_no",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Email",
      dataIndex: "primary_email",
      key: "primary_email",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        
          return <> {record.student_status}</>;
      
      },
    },
    {
      title: "Action",
      key: "Action",
      render: (text, record) => {
        return (
          <>
            <Button
              type="primary"
              size="small"
              onClick={() =>
                navigate(
                  "/semester-registration-student/" +
                    id +
                    "/" +
                    sem_id +
                    "/" +
                    btoa(record.id)
                )
              }
              style={{ marginRight: "5px" }}
            >
              Semester Registration
            </Button>
          </>
        );
      },
    },
  ];
  // DB list
  const REGISTER_LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("acad_calender_id", atob(id));
    FORM_DATA.append("semester_id", atob(sem_id));
    const API_RESPONSE = await SEMESTER_REGISTRATION_VIEW(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_table_list(API_RESPONSE?.data?.semester_registration);
      setFilteredData(API_RESPONSE?.data?.semester_registration);
      set_loader(false);
    } else {
      set_loader(false);
    }
  };

  useEffect(() => {
    LIST_API();
    REGISTER_LIST_API();
  }, [history]);
  return (
    <>
      {page_loader ? (
        <SisLoader />
      ) : (
        <>
          <div>
            <div className="theme-content-head">
              <div className="theme-content-left-head">
                <h3>Semester Registration</h3>
              </div>
              <div className="theme-content-right-head"></div>
            </div>
            {/* <div className='sis-tab-menu'>
                    <span className='active'>Academic Calendar</span>
                    <span  onClick={() => navigate('/semester-courses/')}>Schedule</span>
                </div> */}
            <ul className="semester-tabs">
              {sem_courses_list?.length > 0 && (
                <>
                  {sem_courses_list.map((item, index) => (
                    <>
                      {sem_id ? (
                        <>
                          {item.id === parseInt(atob(sem_id)) ? (
                            <>
                              <li
                                className="active"
                                onClick={() =>
                                  navigate(
                                    "/semester-registration-view/" +
                                      id +
                                      "/" +
                                      btoa(item.id)
                                  )
                                }
                              >
                                {item.title}
                              </li>
                            </>
                          ) : (
                            <>
                              <li
                                onClick={() =>
                                  navigate(
                                    "/semester-registration-view/" +
                                      id +
                                      "/" +
                                      btoa(item.id)
                                  )
                                }
                              >
                                {item.title}
                              </li>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <li
                            className={index === 0 ? "active" : ""}
                            onClick={() =>
                              navigate(
                                "/semester-registration-view/" +
                                  id +
                                  "/" +
                                  sem_id
                              )
                            }
                          >
                            {item.title}
                          </li>
                        </>
                      )}
                    </>
                  ))}
                </>
              )}
            </ul>
            <div className="">
            <div className="input-box" style={{ maxWidth:"250px", marginTop:"25px" }}>
          <Input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
          />
          {errors?.code && (
            <span style={{ color: "red" }}>{errors?.code[0]}</span>
          )}
        </div>
              {loader ? (
                <SisLoader />
              ) : (
                <>
                 <Table columns={columns} dataSource={filteredData} />
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SemesterRegistrationView;
