import { useEffect, useState } from "react";
import {SIS_STORAGE, STUDENT_PROFILE_API_LIST} from "../apis/apis"

const LayoutStudentName = () => {

    const[student_name, set_Student_Name] = useState('');
    const[api_image, set_Api_Image] = useState('');

    const STUDENTINFORMATION = async () =>{

        try {
            const STUDENT_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
            const FORM_DATA = new FormData();
            FORM_DATA.append("student_id", STUDENT_DATA.id);
            FORM_DATA.append("token", STUDENT_DATA.token);
            const VIEW_STUDENT_NAME_API_RESPONSE = await STUDENT_PROFILE_API_LIST(FORM_DATA);
            if(VIEW_STUDENT_NAME_API_RESPONSE?.data?.status)
            {
                const userData = VIEW_STUDENT_NAME_API_RESPONSE?.data?.data?.personal_details;
                const fullName = (userData?.middle_name === null || userData?.middle_name === "null") ?
                userData?.first_name + " " + userData?.last_name :
                userData?.first_name + " " + userData?.middle_name + " " + userData?.last_name;

                set_Student_Name(fullName);
                set_Api_Image(userData?.image);
            }
           
        } 
        catch (error) {
            console.error("Error fetching Faculty data:", error);
        }

    }

useEffect(()=>{

        STUDENTINFORMATION();

},[]);

    return(
        <>
        <div className="theme-user">
                      <div className="theme-user-image">
                       {api_image && (
                            <div className="theme-user-image">
                                 <img
                            src={SIS_STORAGE + "/enrollments/" + api_image}
                              style={{ width: "50px", height: "50px", borderRadius: "50%",  }}
                            ></img>
                            </div>
                      )}
                      </div>
                      <div className="theme-user-detail">
                        <p style={{textTransform: "capitalize", padding: "0 5px 0 10px", wordBreak: "break-word"}}>
                          <span>{student_name}</span>
                          <span>Student</span>
                        </p>
                      </div>
                    </div>
        </>
    )
}

export default LayoutStudentName;