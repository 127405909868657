import React, { useEffect, useState } from 'react';
import { Button, Table, Popconfirm, notification, Input, Tooltip } from 'antd';
import { FACULTIES_LIST, STATUS_FACULTY, DELETE_FACULTY } from '../../apis/apis';
import { useNavigate } from 'react-router-dom';
import SisLoader from '../../widgets/loader';
import { DeleteOutlined, EditOutlined, StopOutlined, SmileOutlined, CloudDownloadOutlined, FilePdfOutlined } from '@ant-design/icons';
import handleStatusText from 'react-highlight-words';
import { BACKEND_URL } from '../../config/config'; 
import './style.css'

const FacultiesList = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(true);
    const [tableList, setTableList] = useState([]);
    const [errors, setErrors] = useState([]);
    const [popStatusDescription, set_popStatusDescription] = useState('Do you want to change Status of this User');
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    // Search 
    const handleSearch = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        const filtered = tableList.filter(item =>
            item.id.toString().toLowerCase().includes(query.toString().toLowerCase()) ||
            item.name.toString().toLowerCase().includes(query.toString().toLowerCase()) ||
            item.phone.toString().toLowerCase().includes(query.toString().toLowerCase()) ||
            item.email.toString().toLowerCase().includes(query.toString().toLowerCase()) ||
            // item.address.toString().toLowerCase().includes(query.toString().toLowerCase()) ||
            item.department.toString().toLowerCase().includes(query.toString().toLowerCase()) ||
            item.designation.toString().toLowerCase().includes(query.toString().toLowerCase())
        );
        if(query === ''){
            setFilteredData(tableList);
        }else{
            setFilteredData(filtered);
        }
    };

    const STATUS_API = async (id) => {
        setLoader(true);
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('id', id);
        const API_RESPONSE = await STATUS_FACULTY(FORM_DATA);
        if (API_RESPONSE.data.status) {
            notification.open({
                message: 'Success!!',
                description: 'Faculty status successfully changed.',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
            navigate('/facultiesList');
        } else {
            setErrors(API_RESPONSE.data.errors);
            setLoader(false);
        }
    };

    const DELETE_API = async (id) => {
        setLoader(true);
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('id', id);
        const API_RESPONSE = await DELETE_FACULTY(FORM_DATA);
        if (API_RESPONSE.data.status) {
            notification.open({
                message: 'Success!!',
                description: 'Faculty successfully deleted.',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
            navigate('/facultiesList');
        } else {
            setErrors(API_RESPONSE.data.errors);
            setLoader(false);
        }
    };


    const handleStatusText = (id, status) => {
        if (status == 1) {
            set_popStatusDescription('Do you want to make this user Inactive');
        } else { set_popStatusDescription('Do you want to make this user Active'); }
    }


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: 150
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            className: 'custom-address-column', 

        },
        {
            title: 'Department',
            dataIndex: 'department',
            key: 'department',
            className: 'custom-department-column'
        },
        {
            title: 'Designation',
            dataIndex: 'designation',
            key: 'designation',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <span className={record.status ? 'table-status-activate' : 'table-status-deactivate'}>
                    {record.status ? 'Active' : 'Inactive'}
                </span>
            ),
        },
        {
            title: 'Action',
            key: 'Action',
            render: (text, record) => (
                <>
                    <Popconfirm
                        title="Change Status"
                        description={popStatusDescription}
                        onConfirm={() => {
                            STATUS_API(btoa(record.id))
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => {
                                handleStatusText(record.id, record.status)
                            }}
                            style={{ backgroundColor: "#888888", marginRight: "5px" }}>
                            <div className="tooltip"> <StopOutlined /> <span className="tooltiptext" >Status Change</span> </div>
                        </Button>
                    </Popconfirm>
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => navigate('/edit-faculty/' + btoa(record.id))}
                        style={{ marginRight: "5px" }}>
                        <div className="tooltip"> <EditOutlined /> <span className="tooltiptext"  style={{width: '80px'}}>Edit n View</span> </div>
                    </Button>
                    <Popconfirm
                        title="Delete"
                        description="Are you sure to delete this user?"
                        onConfirm={() => DELETE_API(btoa(record.id))}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary" size="small" danger>
                            <div className="tooltip"> <DeleteOutlined /> <span className="tooltiptext"  style={{width: '80px'}}>Delete</span> </div>
                        </Button>
                    </Popconfirm>
                </>
            ),
        },
    ];

    const LIST_API = async () => {
        const FACULTIES_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', FACULTIES_DATA.token);
        const API_RESPONSE = await FACULTIES_LIST(FORM_DATA);
        if (API_RESPONSE.data.status) {
            setTableList(API_RESPONSE.data.faculty);
            setFilteredData(API_RESPONSE.data.faculty);
            setLoader(false);
        } else {
            setLoader(false);
        }
    };

    useEffect(() => {
        LIST_API();
    }, [loader]);

    return (
        <div>
            <div>
                <div className='theme-content-head'>
                    <div className='theme-content-left-head'>
                        <h3>Faculties List</h3>
                    </div>
                    <div className='theme-content-right-head'>
                        <Button type='primary' onClick={() => navigate('/add-faculty')}>Add Faculty</Button>

                        {/* CSV Download button */}
                        <Tooltip title="Download Users List CSV">
                            <Button type='primary' 
                            onClick={() => window.location = BACKEND_URL + '/download-faculties/' + JSON.parse(localStorage.getItem('sis_user_data')).token} ghost style={{ marginLeft: "5px" }}><CloudDownloadOutlined /></Button>
                        </Tooltip>

                        {/* PDF Download button  */}
                        <Tooltip title="Download Users List PDF">
                            <Button type='primary' 
                            onClick={() => window.location = BACKEND_URL + '/download-faculties-pdf/' + JSON.parse(localStorage.getItem('sis_user_data')).token} ghost style={{ marginLeft: "5px" }}><FilePdfOutlined /></Button>
                        </Tooltip>


                    </div>
                </div>
                <div className='theme-content-head'>
                    <div className='input-box'>
                        <Input
                            type="text"
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={handleSearch}
                        />
                        {errors?.code && <span style={{ color: "red" }}>{errors?.code[0]}</span>}
                    </div>
                </div>
                {loader ? <SisLoader /> : <Table columns={columns} dataSource={filteredData} />}
            </div>
        </div>
    );
};

export default FacultiesList;